import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {CustomValidations, Events, Globals, YupValidations} from "../utils/Constants";
import {setLocale} from 'yup';
import queryString from 'query-string';
import {CONFIRM_EMAIL} from "../graph/mutation/CreateUserMutation";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import {Mutation} from "react-apollo";
import CantProcess from "../utils/CantProcess";
import BrandLogo from "../utils/BrandLogo";
import {Formik} from "formik";
import * as yup from "yup";
import PubSub from "pubsub-js";

setLocale(YupValidations);

const schema = yup.object().shape({
  securityCode: yup.string().required().length(4).matches(CustomValidations.numberRegex, CustomValidations.numberRegexError)
});

export default class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showCompleteForm: false,
      securityCode: '',
    };

  }

  componentDidMount() {
    PubSub.publish(Events.cleanLogin, {});
  }

  render() {
    let params = queryString.parse(this.props.location.search)

    if (params.confirmation) {
      return (
        <Row>
          <Col lg={4} xs={1}/>
          <Col lg={4} xs={10} className="user_card" style={{color: "black"}}>
            <BrandLogo/>
            <Mutation mutation={CONFIRM_EMAIL}
              variables={{
                token: params.confirmation,
                securityCode: this.state.securityCode
              }}
              onError={(error) => {
                this.setState({
                  error: true
                })
              }}
              onCompleted={(data) => {
                localStorage.setItem(Globals.tokenKey, data.siveConfirmation.token);
                localStorage.setItem(Globals.userIdKey, data.siveConfirmation.userId);
                setTimeout(() => {
                  PubSub.publish(Events.successLogin, data);
                  this.props.history.push({
                    pathname: '/home',
                  })
                }, 2000);
              }}

            >
              {(confirmForm, result) => {
                const {data, loading} = result;

                if (loading) {
                  return (
                    <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
                } else {
                  return (
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        securityCode: '',
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,

                      }) => (
                        <Form className="form_container" noValidate onSubmit={event => {
                          handleSubmit();
                          if (!isValid) {
                            event.preventDefault();
                            event.stopPropagation();
                            return;
                          }
                          this.setState({
                            securityCode: values.securityCode,
                          }, () => {
                            return confirmForm();
                          })

                        }}>
                          {Globals.registerInstructions}

                          <hr/>
                          {data && data.siveConfirmation.token ?
                            <>
                              <Alert variant="success">
                                {Globals.confirmSuccess} <Spinner animation="border" role="status"><span
                                className="sr-only">Cargando...</span></Spinner>
                              </Alert>
                            </> : this.state.error ? <Alert variant="danger">
                              {Globals.confirmError}
                            </Alert> : ""
                          }
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                              <Form.Label>Código de Seguridad</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Escribe aquí el código"
                                name="securityCode"
                                value={values.securityCode}
                                onChange={e=> {
                                  handleBlur(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                isValid={touched.securityCode && !errors.securityCode}
                                isInvalid={!!errors.securityCode}
                              />
                              <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                {errors.securityCode}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>

                          <Button variant="primary" block type={"submit"} disabled={!isValid || Object.values(touched).length === 0}>
                            Click aquí para Confirmar tu cuenta
                          </Button>
                        </Form>
                      )}
                    </Formik>

                  )
                }
              }}
            </Mutation>

          </Col>
          <Col lg={4} xs={1}/>
        </Row>
      )
    } else {
      return <CantProcess/>
    }
  }
}
