import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import {FaExclamationTriangle} from "react-icons/all";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import 'react-dropzone-uploader/dist/styles.css'
import {FIND_ALERT_COMMENTS} from "../graph/query/CommentQuery";
import {Query} from "react-apollo";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Title from "../utils/Title";
import {withSizes} from "react-sizes";
import {mapSizesToProps} from "../utils/Constants";


class LastEvents extends Component {

  state = {
    show: false,
    loading: false,
    toolTipText: this.props.toolTipText,
    title: this.props.title

  };

  handleClose = () => {
    this.setState({
      show: false
    });
  };

  handleShow = () => {
    this.setState({
      show: true
    })
  };

  goToVehicle = (e, plate) => {
    this.props.history.push({
      pathname: '/vehicle',
      state: {plate: plate}
    })
  };

  render() {
    return (<>
      <OverlayTrigger className="float-right"
        overlay={<Tooltip id="tooltip-disabled">{this.state.toolTipText}</Tooltip>}>
        <Button variant="danger" className="float-right" onClick={this.handleShow}><FaExclamationTriangle
          size="25"/></Button>
      </OverlayTrigger>
      <Modal
        style={{color: "black"}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={false}
        show={this.state.show}
        onHide={this.handleClose}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><Title text={this.props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{maxHeight:"350px", overflowY:"auto"}}>
          <Query query={FIND_ALERT_COMMENTS}
            fetchPolicy="cache-and-network">
            {({loading, error, data, fetchMore}) => {
              if (loading) return (
                <div className="my-auto d-flex justify-content-center">
                  <Spinner animation="border" role="status"><span className="sr-only">Loading...</span>
                  </Spinner>
                </div>);
              if (error) return `Error! ${error.message}`;

              return (
                <ListGroup as="ul">
                  {data.comentarios.map((message, idx) => (
                    <ListGroup.Item key={idx} as="li" style={{fontSize:this.props.isMobile?"0.8rem":"1.0rem"}}>PLACA:<Button variant="link" size="sm" onClick={e=>{this.goToVehicle(e, message.vehiculo.placa)}}>{message.vehiculo.placa}</Button>: ALERTA {message.text}</ListGroup.Item>
                    ))}
                </ListGroup>
              )
            }}

          </Query>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>);
  }
}
export default withSizes(mapSizesToProps)(LastEvents)
