import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Logo from "../utils/Logo";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import {FaSearchPlus} from "react-icons/all";
import LastEvents from "../vehicle/LastEvents";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Globals} from "../utils/Constants";


export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      plate: ''
    };
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value) {
      value = e.target.value.toUpperCase();
    }
    if (value && value.length > 6) {
      value = value.substr(0, 6);
    }
    if(value){
      value = value.replace(/[^a-zA-Z0-9]/g, "");
    }
    this.setState({
      [e.target.name]: value
    })

  };

  findVehicle = () => {
    this.props.history.push({
      pathname: '/vehicle',
      state: {
        plate: this.state.plate,
        reload: true
      }
    })
  }


  render() {
    return (
      <>
        <LastEvents history={this.props.history} toolTipText="Click Aquí para ver las últimas ALERTAS en SIVE!"
          title="ALERTAS EN SIVE"/>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <Logo logoType="mainLogo"/>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <InputGroup className="col-md-8">
                <FormControl
                  name="plate"
                  className="rounded-left"
                  placeholder="Digite aquí la placa del vehículo"
                  aria-label="Digite aquí la placa del vehículo"
                  aria-describedby="basic-addon2"
                  value={this.state.plate}
                  onChange={e => this.handleChange(e)}
                />
                <InputGroup.Append>
                  <OverlayTrigger className="float-left"
                    overlay={<Tooltip id="tooltip-disabled">{Globals.toolTipFindVehicle}</Tooltip>}>
                    <Button
                      variant="primary"
                      className="rounded-right"
                      onClick={this.findVehicle}>
                      Buscar <FaSearchPlus/>
                    </Button>
                  </OverlayTrigger>

                </InputGroup.Append>
              </InputGroup>

            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
