import React, {Component} from "react";
import {Query} from "react-apollo";
import {ABOUT_US_QUERY} from "../graph/query/AboutUsQuery";
import Spinner from "react-bootstrap/Spinner";

export default class AboutUs extends Component {


  render() {
    return (<>
      <Query query={ABOUT_US_QUERY}
        fetchPolicy="cache-and-network"
      >
        {({loading, error, data, fetchMore}) => {
          if (loading) {
            return (
              <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
          } else {
            return (<>

                {data.nosotros.map((article, idx) => (
                  <>
                    {article.tipo === "PRINCIPAL" ?
                      <div className="text-dark">
                        <div className="container py-5">
                          <div className="row h-100 align-items-center py-5">
                            <div className="col-lg-6">
                              <h1 className="display-4">{article.titulo}</h1>
                              <p className="lead  mb-0">{article.texto}</p>
                              <p className="lead "><a href={article.enlace}
                                className="">
                                <u>{article.textoEnlace}</u></a>
                              </p>
                            </div>
                            <div className="col-lg-6 d-none d-lg-block">
                              <img src={article.foto.url} alt=""
                                className="img-fluid"/></div>
                          </div>
                        </div>
                      </div> : ""}
                  </>


                ))}
                <div className="py-5 text-dark">
                  <div className="container py-5">
                    {data.nosotros.map((article, idx) => (
                      <>
                        {article.tipo === "SECUNDARIO" ?
                          <>{idx % 2 === 0 ?
                            <div className="row align-items-center mb-5">
                              <div className="col-lg-6 order-2 order-lg-1"><i
                                className="fa fa-bar-chart fa-2x mb-3 text-primary"/>
                                <h2 className="font-weight-light">{article.titulo}</h2>
                                <p className="font-italic  mb-4">{article.texto}</p><a href={article.enlace}
                                  className="btn btn-light px-5 rounded-pill shadow-sm">{article.textoEnlace}</a>
                              </div>
                              <div className="col-lg-5 px-5 mx-auto order-1 order-lg-2"><img
                                src={article.foto.url} alt=""
                                className="img-fluid mb-4 mb-lg-0"/>
                              </div>
                              <hr />
                            </div> :
                            <div className="row align-items-center">
                              <div className="col-lg-5 px-5 mx-auto"><img
                                src={article.foto.url} alt=""
                                className="img-fluid mb-4 mb-lg-0"/></div>
                              <div className="col-lg-6"><i className="fa fa-leaf fa-2x mb-3 text-primary"/>
                                <h2 className="font-weight-light">{article.titulo}</h2>
                                <p className="font-italic  mb-4">{article.texto}</p>
                                <a href={article.enlace} className="btn btn-light px-5 rounded-pill shadow-sm">{article.textoEnlace}</a>
                              </div>
                              <hr />
                            </div>
                          }</>

                          : ""}
                      </>
                    ))}
                  </div>
                </div>
              </>
            )
          }
        }}
      </Query>
    </>);
  }
}
