import React, {Component} from "react";

export default class Alliances extends Component {

  render() {
    return (<>
      Alliances
    </>);
  }
}
