import React, {Component} from "react";
import Logo from "./Logo";

export default class BrandLogo extends Component {

  render() {
    return (<div className="d-flex justify-content-center">
      <div className="brand_logo_container">
        <Logo width="150px" logoType="justLogo" className="brand_logo"/>
      </div>
    </div>);
  }
}
