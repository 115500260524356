import React, {Component} from "react";

import {CustomValidations} from "../utils/Constants";
import Spinner from "react-bootstrap/Spinner";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Mutation} from "react-apollo";
import * as yup from "yup";
import {UPDATE_USER} from "../graph/mutation/UpdateUserMutation";

const schema = yup.object().shape({
  name: yup.string().required().max(20).min(5),
  lastName: yup.string().required().max(20).min(5),
  cellphone: yup.string().required().length(10).matches(CustomValidations.numberRegex, CustomValidations.numberRegexError)
});


export default class UpdateProfile extends Component {


  constructor(props) {
    super(props);
    this.state = {
      name: this.props.user.name,
      lastName: this.props.user.lastName,
      cellphone: this.props.user.celular,
      userId: this.props.user.id

    }
  }

  render() {
    return (
      <Mutation mutation={UPDATE_USER}
        variables={{
          name: this.state.name,
          lastName: this.state.lastName,
          cellphone: this.state.cellphone,
          userId: this.state.userId
        }}
        onCompleted={(data) => {
          if(this.props.onComplete) {
            this.props.onComplete();
          }
        }}
        onError={(error) => {
          this.setState({
            error: true
          })
        }}
      >
        {(updateForm, result) => {
          const {data, loading} = result;
          if (loading) {
            return (
              <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
          } else {
            return (
              <Formik
                validationSchema={schema}
                initialValues={{
                  name: this.state.name || '',
                  lastName: this.state.lastName || '',
                  cellphone: this.state.cellphone || '',
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,

                }) => (
                  <Form noValidate onSubmit={event => {
                    handleSubmit();

                    if (!isValid) {
                      event.preventDefault();
                      event.stopPropagation();
                      return;
                    }
                    this.setState({
                      name: values.name,
                      lastName: values.lastName,
                      cellphone: values.cellphone
                    }, () => {
                      return updateForm();
                    })

                  }}>
                    {data && data.updateUser.user.id ?
                      <Alert variant="success">
                        {this.props.successMessage}
                        {this.props.waitFor?<Spinner animation="border" role="status"><span
                          className="sr-only">Cargando...</span></Spinner>:""}
                      </Alert> : ""
                    }
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Nombres</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Tu Nombre"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.name && !errors.name}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Tu Apellido"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.lastName && !errors.lastName}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Número de Celular</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ej 3201234567"
                          name="cellphone"
                          value={values.cellphone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.cellphone && !errors.cellphone}
                          isInvalid={!!errors.cellphone}
                          readOnly={true}
                        />
                        <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {errors.cellphone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Button variant="primary" type={"submit"}
                      disabled={!isValid || Object.values(touched).length === 0}>
                      {this.props.submitText}
                    </Button>
                  </Form>
                )}
              </Formik>

            )
          }
        }}
      </Mutation>

    );
  }
}
