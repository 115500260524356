import {gql} from "apollo-boost";


// language=GraphQL
export const CREATE_USER_MUTATION = gql`
    mutation CreateUser ($email: String!, $password: String!, $name: String!, $lastName:String!)  {
        createUser(input: {data: {
            username:$email,
            password:$password,
            confirmed: true,
            role:"5ecb1b53e614c868e1846c14",
            email: $email,
            name:$name,
            lastName:$lastName
        }}){
            user{
                id
                createdAt
            }
        }
    }
`;

export const REGISTER_USER = gql`
    mutation RegisterUser ($email:String!, $password:String!, $name: String!, $lastName: String!, $cellphone: String!){
        siveRegistration(registration:
        {
            name: $name,
            lastName: $lastName,
            email:$email,
            password:$password,
            cellphone:$cellphone
        }){
            status
        }
    }
`

export const CONFIRM_EMAIL = gql`
    mutation ConfirmUser ($token: String!, $securityCode: String!){
        siveConfirmation(confirmation: {token: $token
            securityCode:$securityCode
        }){
            status
            token
            userId
        }
    }
`


