import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import {Mutation} from "react-apollo";
import {CREATE_SYSTEM_COMMENT_MUTATION} from "../graph/mutation/CreateCommentMutation";
import ListGroup from "react-bootstrap/ListGroup";
import "react-image-gallery/styles/css/image-gallery.css";

import {
  fuelList,
  getCurrentUser,
  Globals,
  humanVehicleTypes,
  isLoggedIn, serviceList,
  statusTypeList,
  statusTypes,
  vehicleTypeList
} from "../utils/Constants";
import Editable from "../utils/Editable";
import socketIOClient from "socket.io-client";
import moment from "moment";
import ImageGallery from "react-image-gallery";
import FileUpload from "./FileUpload";
import Back from "../utils/Back";
import axios from "axios";
import ModalAuthorization from "./ModalAuthorization";
import RotateModal from "./RotateModal";
import uuid from 'react-uuid'

const socket = socketIOClient(process.env.REACT_APP_SOCKET_SERVER);


export default class Features extends Component {

  constructor(props) {
    super(props);

    this.state = {
      vehicle: this.props.vehicle,
      plate: this.props.plate,
      images: [],
      showModalAuthorization:false,
      cacheDate: ''
    };
  }

  sendSystemMessage = (saveAttributeFunction, field, formerValue, humanTextFunction) => {
    return new Promise((resolve) => {
      let user = getCurrentUser();
      let cFormerValue = formerValue || "INDEFINIDO";
      let cNewValue = field[Object.keys(field)[0]];
      if (humanTextFunction) {
        cFormerValue = humanTextFunction(formerValue).text;
        cNewValue = humanTextFunction(field[Object.keys(field)[0]]).text;
      }
      let message = `El campo "${Object.keys(field)[0]}" del vehículo ha sido actualizado a 
        "${cNewValue}" por el usuario ${user.name} ${user.lastName}. El valor anterior era "${cFormerValue}"
      `;
      saveAttributeFunction({
        variables: {
          vehicleId: this.state.vehicle.id,
          text: message,
          vehicle: field,
          user: user.id
        }
      }).then(data => {
        this.setState(prevState => {
          let vehicle = Object.assign({}, prevState.vehicle);
          vehicle[Object.keys(field)[0]] = field[Object.keys(field)[0]];
          return {vehicle: vehicle};
        })
        socket.emit('chat message', {
          roomId: this.state.plate,

          createdAt: moment(new Date()).format("DD-MMM-YYYY, hh:mm A"),
          text: message,
          vehiculo: {
            id: this.state.vehicle.id
          },
          "tipoComentario": "SISTEMA",
          "user": {
            "id": user.id,
            "name": user.name,
            "lastName": user.lastName,
            "photo": {
              "url": user.photo ? user.photo.url : null
            }
          }
        });
        resolve(data);
      });

    });
  }

  reloadImages = (images) => {
    if (images.data.length > 0 && this.state.images[0].original === Globals.defaultVehiclePhoto) {
      this.setState({
        images: []
      }, () => {
        images.data.forEach(image => {
          this.setState(prevState => ({
            images: [...prevState.images, {
              original: image.url,
            }]
          }));
        })
      });
    } else {
      images.data.forEach(image => {
        this.setState(prevState => ({
          images: [...prevState.images, {
            original: image.url,
          }]
        }));
      })
    }
  }

  refreshImages = () =>{
    if (this.state.vehicle.fotos && this.state.vehicle.fotos.length > 0) {
      this.setState({
        images: [],
        cacheDate: uuid()
      }, () => {
        setTimeout(_=>{
          this.state.vehicle.fotos.forEach((photo, idx) => {
            this.setState(prevState => ({
              images: [...prevState.images, {
                original: photo.url+"?"+this.state.cacheDate,
              }]
            }));
          })
        }, 1000);

      });
    }
  }

  componentDidMount() {
    if (this.state.vehicle.fotos && this.state.vehicle.fotos.length > 0) {
      this.state.vehicle.fotos.forEach((photo, idx) => {
        this.setState(prevState => ({
          images: [...prevState.images, {
            original: photo.url,
          }]
        }));
      })
    } else {
      this.setState({
        images: [{
          original: Globals.defaultVehiclePhoto,
        }]
      })
    }
  }

  permissionValidator = () => {
    let token = localStorage.getItem(Globals.tokenKey);
    return axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/confirmation/validateEdition`, {
      "vehicleId": this.state.vehicle.id,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  permissionErrorBehavior = () => {
    this.setState({
      showModalAuthorization:true
    })
  }

  closeAuthorizationModal = () =>{
    this.setState({
      showModalAuthorization:false
    })
  }

  render() {
    return (
      <Card className="text-center" style={{color: "black"}}>
        <ModalAuthorization show={this.state.showModalAuthorization}  close={this.closeAuthorizationModal} vehicle={this.state.vehicle} validator={this.permissionValidator}/>
        <Card.Header><Back history={this.props.history} /><span style={{fontSize: "1.4em"}}>Ficha de vehículo {this.state.vehicle.placa}</span>
          {isLoggedIn() ? <FileUpload
            serverValidation={this.permissionValidator}
            onServerErrorValidation={this.permissionErrorBehavior}
            reloadImages={this.reloadImages}
            toolTipText="Click Aquí para adjuntar más fotos de este vehículo!"
            title="Sube Imagenes del vehículo"
            entity="vehiculo"
            entityId={this.state.vehicle.id}
            field="fotos"
            multiple={true}
          /> : ""}
          {isLoggedIn() ? <RotateModal
            serverValidation={this.permissionValidator}
            onServerErrorValidation={this.permissionErrorBehavior}
            refreshImages={this.refreshImages}
            images={this.state.images}
            toolTipText="Click Aquí para rotar las imagenes!"
            title="Rotar imágenes"
          /> : ""}
        </Card.Header>
        <ImageGallery items={this.state.images} showThumbnails={false} sizes="16x16"/>
        <Mutation mutation={CREATE_SYSTEM_COMMENT_MUTATION}>
          {saveAttribute => (
            <ListGroup variant="flush" >
              <ListGroup.Item style={{padding: "0", backgroundColor:statusTypes(this.state.vehicle.estado).color }} >
                <Editable
                  fromList={statusTypeList}
                  blocked={!isLoggedIn()}
                  fieldName="ESTADO"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.estado}
                  fieldPlaceholder="Seleccione el nuevo estado del vehículo"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {estado: updatedValue}, this.state.vehicle.estado, statusTypes)
                  }}
                />
              </ListGroup.Item>
              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  blocked={!isLoggedIn()}
                  fieldName="MARCA"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.marca}
                  fieldPlaceholder="Digite la marca correcta"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {marca: updatedValue}, this.state.vehicle.marca)
                  }}/>
              </ListGroup.Item>
              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  blocked={!isLoggedIn()}
                  fieldName="REFERENCIA"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.referencia}
                  fieldPlaceholder="Digite Nueva Referencia"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {referencia: updatedValue}, this.state.vehicle.referencia)
                  }}
                />
              </ListGroup.Item>
              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  fromList={serviceList}
                  blocked={!isLoggedIn()}
                  fieldName="CLASE DE SERVICIO"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.servicio}
                  fieldPlaceholder="Digite nueva clase de servicio"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {servicio: updatedValue}, this.state.vehicle.servicio)
                  }}
                />
              </ListGroup.Item>
              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  fromList={fuelList}
                  blocked={!isLoggedIn()}
                  fieldName="TIPO DE COMBUSTIBLE"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.combustible}
                  fieldPlaceholder="Digite nuevo tipo de combustible"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {combustible: updatedValue}, this.state.vehicle.combustible)
                  }}
                />
              </ListGroup.Item>
              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  blocked={!isLoggedIn()}
                  fieldName="MODELO"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.modelo}
                  fieldPlaceholder="Digite la modelo correcto"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {modelo: updatedValue}, this.state.vehicle.modelo)
                  }}
                />
              </ListGroup.Item>
              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  fromList={vehicleTypeList}
                  blocked={!isLoggedIn()}
                  fieldName="CLASE"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.tipo}
                  fieldPlaceholder="Seleccione la clase correcta de vehículo"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {tipo: updatedValue}, this.state.vehicle.tipo, humanVehicleTypes)
                  }}
                />
              </ListGroup.Item>
              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  blocked={!isLoggedIn()}
                  fieldName="CIUDAD MATRICULA"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.ciudadMatricula}
                  fieldPlaceholder="Digite la ciudad correcta"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {ciudadMatricula: updatedValue}, this.state.vehicle.ciudadMatricula)
                  }}
                />
              </ListGroup.Item>

              <ListGroup.Item style={{padding: "0"}}>
                <Editable
                  blocked={!isLoggedIn() || this.state.vehicle.vin}
                  fieldName="VIN"
                  serverValidation={this.permissionValidator}
                  onServerErrorValidation={this.permissionErrorBehavior}
                  fieldValue={this.state.vehicle.vin}
                  fieldPlaceholder="Digite el VIN del vehículo"
                  saveFunction={updatedValue => {
                    return this.sendSystemMessage(saveAttribute, {vin: updatedValue}, this.state.vehicle.vin)
                  }}
                />
              </ListGroup.Item>

            </ListGroup>
          )}
        </Mutation>

      </Card>);
  }
}
