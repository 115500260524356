import {gql} from "apollo-boost";

// language=GraphQL
export const CREATE_COMMENT_MUTATION = gql`
    mutation CreateComment ($vehicle: ID!,$user:ID, $text: String!) {
        createComentario(
            input: {
                data:
                {
                    vehiculo: $vehicle,
                    tipoComentario:NORMAL
                    text: $text
                    user:$user
                }
            }
        ){
            comentario{
                id
            }
        }
    }
`;


export const CREATE_SYSTEM_COMMENT_MUTATION = gql`
    mutation CreateSystemComment($vehicleId: ID!,$text: String!, $vehicle: editVehiculoInput!, $user:ID) {
        updateVehiculo(
            input: {
                where: { id: $vehicleId }
                data: $vehicle
            }
        ) {
            vehiculo{
                id
                marca
            }
        }
        createComentario(
            input: {
                data:
                {
                    vehiculo: $vehicleId,
                    tipoComentario:SISTEMA,
                    text: $text,
                    user:$user
                }
            }
        ){
            comentario{
                id
            }
        }
    }
`
