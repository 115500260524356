import React, {Component} from "react";
import {FIND_COMMENTS_BY_VEHICLE} from "../graph/query/CommentQuery";
import {Query} from "react-apollo";
import MessageList from "./MessageList";
import Spinner from "react-bootstrap/Spinner";
import {isLoggedIn} from "../utils/Constants";

export default class OldMessages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vehicle: this.props.vehicle,
      userId: this.props.userId,
      date: this.props.date || new Date(),
      currentCount: 0,
      scrolled: false
    };
  }

  internalScrollDown = () => {
    if (!this.state.scrolled) {
      this.setState({
        scrolled: true
      }, () => {
        this.props.scrollDown();
      });
    }
  }

  render() {
    return (
      <Query query={FIND_COMMENTS_BY_VEHICLE} variables={{page: 0, vehicle: this.state.vehicle, date: this.state.date}}
        fetchPolicy="cache-and-network"
        onCompleted={(data)=>{
          this.internalScrollDown();
        }}
      >
        {({loading, error, data, fetchMore}) => {
          if (loading) return (
            <div className="my-auto d-flex justify-content-center">
              <Spinner animation="border" role="status"><span className="sr-only">Loading...</span>
              </Spinner>
            </div>);
          if (error) return `Error! ${error.message}`;
          if (data.comentarios.length > 0) {
            return (<MessageList comentarios={data.comentarios} userId={this.state.userId} vehicle={this.state.vehicle}
              onLoadMore={() =>
                fetchMore({
                  variables: {
                    page: data.comentarios.length
                  },
                  updateQuery: (prev, {fetchMoreResult}) => {
                    if (!fetchMoreResult) return prev;

                    return Object.assign({}, prev, {
                      comentarios: [...prev.comentarios, ...fetchMoreResult.comentarios]
                    });
                  }
                })
              }

            />)
          }else{
            return (
              <div className="col-sm-12 my-auto d-flex justify-content-center">
                {
                  data.comentarios.length===0 && !isLoggedIn()?<span>Este vehículo aún no tiene comentarios, puedes comentar como anónimo o puedes<a href="/#/register" className="ml-2">registrarte</a></span>:
                  <span>Este vehículo aún no tiene commentarios, puedes comentar para iniciar la historia</span>
                }
              </div>
            )
          }

        }}
      </Query>);
  }
}
