import {gql} from "apollo-boost";


// language=GraphQL
export const LOGIN_MUTATION = gql`
    mutation Login ($email: String!, $password: String!)  {
        login(input: {identifier:$email, password:$password}){
            jwt
            user {
                id
            }
        }
    }
`;
