import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Mutation} from "react-apollo";
import {LOGIN_MUTATION} from "../graph/mutation/LoginMutation";
import PubSub from 'pubsub-js';
import {Events, Globals} from "../utils/Constants";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import {FaKey, FaUser} from "react-icons/all";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import BrandLogo from "../utils/BrandLogo";
import Back from "../utils/Back";
import Card from "react-bootstrap/Card";

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      siveEmail: '',
      sivePassword: '',
      error: false
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  loginRefresh = (data) => {
    if (data && data.login && data.login.jwt) {
      PubSub.publish(Events.successLogin, data);
      localStorage.setItem(Globals.tokenKey, data.login.jwt);
      localStorage.setItem(Globals.userIdKey, data.login.user.id);
      this.props.history.push({
        pathname: '/home'
      })
    }
  }

  render() {
    return (
      <Row>
        <Col lg={4} xs={1}/>
        <Col lg={4} xs={10} className="d-flex justify-content-center user_card" style={{color: "black"}}>
          <Card.Header style={{backgroundColor:"transparent", border: "none"}}><Back history={this.props.history} /></Card.Header>
          <BrandLogo />
          <Mutation mutation={LOGIN_MUTATION}
            variables={{
              email: this.state.siveEmail,
              password: this.state.sivePassword,
            }}
            onError={(error) => {
              this.setState({
                error: true
              })
            }}
          >
            {(loginForm, result) => {
              const {data, loading} = result;

              if (loading) {
                return (
                  <Spinner animation="border" role="status"><span className="sr-only">Cargando...</span></Spinner>)
              } else {
                this.loginRefresh(data);
                return (
                  <Container>
                    <Form className="form_container">
                      {this.state.error?
                        <Alert variant="warning">
                          {Globals.loginError}
                        </Alert>:""
                      }
                      <Form.Group controlId="siveEmail">
                        <Form.Label>Correo Electronico</Form.Label>
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text><FaUser/></InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control type="email" placeholder="tucorreo@ejemplo.com" name="siveEmail"
                            onChange={e => this.handleChange(e)}/>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          {Globals.neverShareInfo}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group controlId="sivePassword">
                        <Form.Label>Contraseña</Form.Label>
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text><FaKey/></InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control type="password" name="sivePassword" placeholder="Password"
                            onChange={e => this.handleChange(e)}/>
                        </InputGroup>

                      </Form.Group>
                      <Form.Group controlId="siveRememberMe">
                        <Form.Check type="checkbox" label="Recuerdame"/>
                      </Form.Group>
                      <Button variant="primary" onClick={loginForm} className="login_btn">
                        Ingresar
                      </Button>

                      <div className="mt-4">
                        <div className="d-flex justify-content-center links">
                          No tienes una cuenta aún <a href="/#/register" className="ml-2">Registrate</a>
                        </div>
                        <div className="d-flex justify-content-center links">
                          <a href="/#/forgot-password">Olvidaste tu contraseña?</a>
                        </div>
                      </div>

                    </Form>
                  </Container>
                  )
              }
            }}
          </Mutation>
        </Col>
        <Col lg={4} xs={1}/>
      </Row>
    );
  }
}
