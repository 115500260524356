import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import {Globals} from "./Constants";

export default class CantProcess extends Component {

  goHome = () => {
    window.location.replace("/#/home");
  }

  render() {
    return (<Row>
      <Col lg={2}/>
      <Col lg={8} className="user_card" style={{color: "black"}}>
        <Button variant="primary" block onClick={this.goHome}>
          {Globals.requestProcessError}
        </Button>
      </Col>
      <Col lg={2}/>
    </Row>);
  }
}
