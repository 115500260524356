import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Mutation} from "react-apollo";
import Spinner from "react-bootstrap/Spinner";
import {REGISTER_USER} from "../graph/mutation/CreateUserMutation";
import Alert from "react-bootstrap/Alert";
import {CustomValidations, Globals, YupValidations} from "../utils/Constants";
import {Formik} from "formik";
import * as yup from 'yup';
import {setLocale} from 'yup';
import BrandLogo from "../utils/BrandLogo";
import Back from "../utils/Back";
import Card from "react-bootstrap/Card";

setLocale(YupValidations);

const schema = yup.object().shape({
  terms: yup.string().required().matches("on"),
  password: yup.string().required().matches(CustomValidations.passwordRegex, CustomValidations.passwordRegexError),
  passwordConfirmation: yup.string().required()
    .oneOf([yup.ref('password'), null], CustomValidations.passwordMatchError),
  email: yup.string().email().required(),
  name: yup.string().required().max(20).min(5),
  lastName: yup.string().required().max(20).min(5),
  cellphone: yup.string().required().length(10).matches(CustomValidations.numberRegex, CustomValidations.numberRegexError)
});


export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      intern: this.props.intern
    };
  }

  render() {

    return (
      <Row>
        {!this.state.intern ? <Col lg={4} xs={1}/> : ""}
        <Col lg={this.state.intern ? 12 : 4} xs={this.state.intern ? 12 : 10} className="user_card"
          style={{color: "black"}}>
          <BrandLogo/>
          <Card.Header style={{backgroundColor:"transparent", border: "none"}}><Back history={this.props.history} /></Card.Header>
          <Mutation mutation={REGISTER_USER}
            variables={{
              email: this.state.email,
              password: this.state.password,
              name: this.state.name,
              lastName: this.state.lastName,
              cellphone: this.state.cellphone
            }}
            onError={(error) => {
              this.setState({
                error: true
              })
            }}
          >
            {(registerForm, result) => {
              const {data, loading} = result;

              if (loading) {
                return (
                  <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
              } else {
                return (
                  <Formik
                    validationSchema={schema}
                    initialValues={{
                      email: '',
                      password: '',
                      passwordConfirmation: '',
                      name: '',
                      lastName: '',
                      cellphone: ''
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,

                    }) => (
                      <Form className="form_container" noValidate onSubmit={event => {
                        handleSubmit();

                        if (!isValid) {
                          event.preventDefault();
                          event.stopPropagation();
                          return;
                        }
                        this.setState({
                          email: values.email,
                          password: values.password,
                          name: values.name,
                          lastName: values.lastName,
                          cellphone: values.cellphone
                        }, () => {
                          return registerForm();
                        })

                      }}>
                        {data && data.siveRegistration.status==='OK' ?
                          <Alert variant="success">
                            {Globals.registerSuccess}
                          </Alert> : ""
                        }

                        {this.state.error?
                          <Alert variant="danger">
                            {Globals.registerFailed}
                          </Alert> : ""
                        }

                        <Form.Row>

                          <Form.Group as={Col} lg={6} sm={12} controlId="formGridName">
                            <Form.Label>Nombres</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Tu nombre"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.name && !errors.name}
                              isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} lg={6} sm={12} controlId="formGridName">
                            <Form.Label>Apellidos</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Tus Apellido"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.lastName && !errors.lastName}
                              isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>


                        <Form.Row>
                          <Form.Group as={Col}  lg={6} sm={12} controlId="formGridEmail">
                            <Form.Label>Correo Electrónico</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="tucorreo@sive.com"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col}  lg={6} sm={12} controlId="formGridEmail">
                            <Form.Label>Celular</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Tu Número de celular"
                              name="cellphone"
                              value={values.cellphone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.cellphone && !errors.cellphone}
                              isInvalid={!!errors.cellphone}
                            />
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.cellphone}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}  lg={6} sm={12} controlId="formGridPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Tu Contraseña"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.password && !errors.password}
                              isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col}  lg={6} sm={12} controlId="formGridPassword">
                            <Form.Label>Confirma tu Contraseña</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirma tu Contraseña"
                              name="passwordConfirmation"
                              value={values.passwordConfirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                              isInvalid={!!errors.passwordConfirmation}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.passwordConfirmation}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Group id="formGridCheckbox">
                          <Form.Check type="checkbox">
                            <Form.Check.Input type="checkbox"
                              name="terms"
                              isInvalid={!!errors.terms}
                              onChange={handleChange}
                              feedback={errors.terms}
                            />
                            <Form.Check.Label style={{cursor: "pointer"}}>
                              Acepto los
                              <a target={"_blank"}
                                rel="noopener noreferrer"
                                href="https://sive-files.s3.amazonaws.com/terminos_y_condiciones_sive_a985c256e2.pdf"> términos
                                y condiciones de SIVE
                              </a>
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                        <Button variant="primary" type={"submit"}
                          disabled={!isValid || Object.values(touched).length === 0}>
                          Registrarse
                        </Button>

                        <div className="mt-4">
                          <div className="d-flex justify-content-center links">
                            ¿Ya tienes una cuenta? <a href="/#/login" className="ml-2">Ingresa</a>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>

                )
              }
            }}
          </Mutation>

        </Col>
        {!this.state.intern ? <Col lg={4} xs={1}/> : ""}
      </Row>
    )
  }
}
