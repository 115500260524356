import {gql} from "apollo-boost";


// language=GraphQL
export const UPDATE_USER = gql`
    mutation UpdateUser($userId: ID!, $name: String, $lastName: String, $cellphone: String){
        updateUser(input: {where:{id:$userId}, data:{name: $name, lastName:$lastName, celular:$cellphone}}){
            user{
                id
                photo{
                    url
                }
                name
                lastName

            }
        }
    }

`

    export const UPDATE_USER_PASSWORD = gql`
    mutation UpdatePasswordUser ($password: String, $userId:ID!) {
        updateUser(input: {
            where: { id: $userId }
            data: {
                password: $password,
            }
        }){
            user{
                id
                name
                lastName
                celular
            }
        }
    }
`;
