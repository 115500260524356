import {gql} from "apollo-boost";


// language=GraphQL
export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword ($email: String!)  {
        forgotPassword(email:$email){
            ok
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword ($password:String!, $passwordConfirmation:String!, $code:String!){
        resetPassword(password: $password, passwordConfirmation:$passwordConfirmation, code:$code){
            jwt
            user{
                id
                username
                email
            }
        }
    }
`
