import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import 'react-dropzone-uploader/dist/styles.css'
import {FaUndo} from "react-icons/all";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import {Mutation} from "react-apollo";
import {ROTATE_IMAGE_MUTATION} from "../graph/mutation/RotateImageMutation";
import Spinner from "react-bootstrap/Spinner";


export default class RotateModal extends Component {

  state = {
    show: false,
    loading: false,
    images: this.props.images,
    toolTipText: this.props.toolTipText,
    title: this.props.title,
    url: "",
    cacheDate: this.props.date
  };

  handleClose = () => {
    this.setState({
      show: false
    });
  };

  handleShow = () => {
    if (this.props.serverValidation) {
      this.props.serverValidation().then(data => {
        this.setState({
          show: true
        })
      }).catch(_ => {
        this.props.onServerErrorValidation();
      })
    } else {
      this.setState({
        show: true
      })
    }
  };

  render() {
    return (<>
      <OverlayTrigger className="float-right"
        overlay={<Tooltip id="tooltip-disabled">{this.state.toolTipText}</Tooltip>}>
        <Button variant="primary" className="float-right" onClick={this.handleShow}><FaUndo size="25"/></Button>
      </OverlayTrigger>
      <Modal
        style={{color: "black"}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={false}
        show={this.state.show}
        onHide={this.handleClose}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Mutation mutation={ROTATE_IMAGE_MUTATION}
            variables={{
              url: this.state.url
            }}>
            {(rotate, result) => {
              const {data, loading} = result;
              if (loading) {
                return (
                  <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
              } else {
                return (
                    <div style={{color: "black", height: "200px", overflowY: "auto"}}>
                      {this.props.images.map((item, idx) => (
                        <Card border="primary" key={idx}>
                          <Card.Body>
                            <Image style={{maxWidth: "100px"}} src={item.original}/>
                            <Button variant="primary" className="float-right" onClick={event => {
                              this.setState({
                                url: item.original,
                              }, () => {
                                rotate().then(data => {
                                  console.log(data);
                                  this.props.refreshImages();
                                });
                              })
                            }}
                            ><FaUndo size="25"/></Button>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  )
              }

            }}
          </Mutation>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>);
  }
}
