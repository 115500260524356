import {gql} from "apollo-boost";

// language=GraphQL
export const FIND_COMMENTS_BY_VEHICLE = gql`
    query CommentByVehicle($page:Int!, $vehicle: String!, $date: DateTime!) {
        comentarios(limit:10,sort: "createdAt:desc", start:$page, where: {vehiculo : $vehicle, createdAt_lte: $date }){
            id
            createdAt
            text
            vehiculo{
                id
            }
            tipoComentario
            user{
                id
                name
                lastName
                corporate
                photo{
                    url
                }
            }
        }
    }
`;

export const FIND_ALERT_COMMENTS = gql`
    {
        comentarios(where:{text_contains: ["robado", "accidente", "estafa", "robo" , "delincuentes", "asesinato", "homicidio", "robar", "ladrones", "delincuentes", "policia", "policía", "fiscalía"]},sort: "createdAt:desc", limit: 50){
            text
            tipoComentario
            vehiculo{
                placa
            }
            user{
                name
                lastName
                corporate
            }
        }
    }
`
