import React from "react";
import {withSizes} from "react-sizes";
import {mapSizesToProps} from "./Constants";


class Title extends React.Component {

  render() {
    return (<h4 style={{fontSize: this.props.isMobile ? "1.2rem" : "1.5rem"}}>{this.props.internal?this.props.internal:""} {this.props.text}</h4>)
  }

}

export default withSizes(mapSizesToProps)(Title)
