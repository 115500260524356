import React, {Component} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FaCheckCircle} from "react-icons/all";
import Badge from "react-bootstrap/Badge";
import {Globals} from "./Constants";

export default class Corporate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    }
  }

  render() {
    return (<>{this.state.user && this.state.user.corporate ? <OverlayTrigger
      overlay={<Tooltip id="tooltip-disabled">{Globals.verifiedUser}</Tooltip>}>
      <Badge variant="success"><FaCheckCircle size="10"/></Badge>
    </OverlayTrigger> : ""}</>);
  }
}
