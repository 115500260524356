import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import {FaImages} from "react-icons/all";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'
import axios from 'axios';
import {Globals} from "../utils/Constants";
import Spinner from "react-bootstrap/Spinner";


export default class FileUpload extends Component {

  state = {
    show: false,
    loading: false,
    entity: this.props.entity,
    entityId: this.props.entityId,
    field: this.props.field,
    multiple: this.props.multiple,
    toolTipText: this.props.toolTipText,
    title: this.props.title,
    source: this.props.source,
    maxFiles: this.props.maxFiles || 5
  };

  handleClose = () => {
    this.setState({
      show: false
    });
  };

  handleShow = () => {
    if (this.props.serverValidation) {
      this.props.serverValidation().then(data => {
        this.setState({
          show: true
        })
      }).catch(_ => {
        this.props.onServerErrorValidation();
      })
    } else {
      this.setState({
        show: true
      })
    }
  };

  getUploadParams = ({meta}) => {
    return {url: 'https://httpbin.org/post'}
  }

  // called every time a file's `status` changes
  handleChangeStatus = ({meta, file}, status) => {
    console.log(status, meta, file)
  }

  // receives array of files that are done uploading when submit button is clicked
  handleSubmit = (files) => {
    this.setState({
      loading: true
    })
    const data = new FormData();
    files.forEach(function (file) {
      data.append('files', file.file);
    });
    data.append("ref", this.state.entity)
    data.append("refId", this.state.entityId)
    data.append("field", this.state.field);
    if (this.state.source) {
      data.append("source", this.state.source)
    }

    let token = localStorage.getItem(Globals.tokenKey);
    axios.post(process.env.REACT_APP_BACKEND_SERVER + "/upload", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      this.setState({
        show: false,
        loading: false
      })
      this.props.reloadImages(res);
    })
  }

  render() {
    return (<>
      <OverlayTrigger className="float-right"
        overlay={<Tooltip id="tooltip-disabled">{this.state.toolTipText}</Tooltip>}>
        <Button variant="primary" className="float-right" onClick={this.handleShow}><FaImages size="25"/></Button>
      </OverlayTrigger>
      <Modal
        style={{color: "black"}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={false}
        show={this.state.show}
        onHide={this.handleClose}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {!this.state.loading ? <Dropzone
            multiple={this.state.multiple}
            maxFiles={this.state.maxFiles}
            maxSizeBytes="2097152"
            inputContent={"Click aquí o arrastra para subir fotos"}
            inputWithFilesContent={"Agregar fotos"}
            submitButtonContent={"Subir Fotos"}
            getUploadParams={this.getUploadParams}
            onChangeStatus={this.handleChangeStatus}
            onSubmit={this.handleSubmit}
            accept="image/*"

          /> : <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" size="lg"><span className="sr-only">Cargando...</span></Spinner>
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>);
  }
}
