
import {gql} from "apollo-boost";

// language=GraphQL
export const RELATED_SITES = gql`
    query {
        sitioRelacionados{
            nombre
            descripcion
            link
            foto{
                url
            }
        }
    }
`;
