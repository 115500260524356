import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FIND_USER_BY_ID_QUERY} from "../graph/query/UserQuery";
import {CustomValidations, Globals, mapSizesToProps} from "../utils/Constants";
import {Mutation, Query} from "react-apollo";
import UpdateProfile from "../register/UpdateProfile";
import UserPhoto from "../utils/UserPhoto";
import FileUpload from "../vehicle/FileUpload";
import Spinner from "react-bootstrap/Spinner";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {UPDATE_USER_PASSWORD} from "../graph/mutation/UpdateUserMutation";
import * as yup from "yup";
import Card from "react-bootstrap/Card";
import Back from "../utils/Back";
import {withSizes} from "react-sizes";
import Title from "../utils/Title";

const schema = yup.object().shape({
  password: yup.string().required().matches(CustomValidations.passwordRegex, CustomValidations.passwordRegexError),
  passwordConfirmation: yup.string().required()
    .oneOf([yup.ref('password'), null], 'El password y su confirmación no coinciden'),
});

class MyProfile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: '',
      password: '',
      passwordConfirmation: ''
    };
  }

  componentDidMount() {
    this.setState({
      userId: localStorage.getItem(Globals.userIdKey)
    });
  }

  onCompleteCallBack = (data) => {
    return data;
  }

  reloadImages = (images) => {
    window.location.reload(false);
    return images;
  }

  render() {
    return (
      <>
        <Query query={FIND_USER_BY_ID_QUERY} variables={{id: this.state.userId}}>
          {({loading, error, data}) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;
            if (data.user) {
              return (
                <Row style={{color: "black"}}>
                  <Col lg={1}/>
                  <Col lg={5}>
                    <Card>
                      <Card.Body>
                        <Back history={this.props.history} />
                        <FileUpload
                          reloadImages={this.reloadImages}
                          toolTipText="Click aquí para cambiar tu foto de perfil!"
                          title="Cambia tu foto"
                          entity="user"
                          entityId={this.state.userId}
                          field="photo"
                          maxFiles={1}
                          multiple={false}
                          source="users-permissions"
                        />
                        <Title internal={<UserPhoto maxWidth={this.props.isMobile?"80px":"150px"} user={data.user}/>} text="Actualiza Tus Datos Básicos" />
                        <hr/>
                        <UpdateProfile
                          successMessage={Globals.updateSuccess}
                          errorMessage={Globals.updateError}
                          phoneValidation={ false}
                          waitFor={false}
                          submitText="Actualiza tus datos"
                          user={data.user}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={5}>
                    <Card>

                      <Card.Body>
                        <Title text="Actualiza tu contraseña"/>
                        <hr/>
                        <Mutation mutation={UPDATE_USER_PASSWORD}
                          variables={{
                            password: this.state.password,
                            userId: this.state.userId,
                          }}
                          onError={(error) => {
                            this.setState({
                              error: true
                            })
                          }}
                        >
                          {(updatePasswordForm, result) => {
                            const {data, loading} = result;

                            if (loading) {
                              return (
                                <Spinner animation="border" role="status"><span
                                  className="sr-only">Loading...</span></Spinner>)
                            } else {
                              return (
                                <Formik
                                  validationSchema={schema}
                                  initialValues={{
                                    password: '',
                                    passwordConfirmation: '',
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,

                                  }) => (
                                    <Form noValidate onSubmit={event => {
                                      handleSubmit();

                                      if (!isValid) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        return;
                                      }
                                      this.setState({
                                        password: values.password,
                                        passwordConfirmation: values.passwordConfirmation,
                                      }, () => {
                                        return updatePasswordForm();
                                      })
                                    }}>
                                      {data && data.updateUser.user.id ?
                                        <Alert variant="success">
                                          {Globals.updatePasswordSuccess}
                                        </Alert> : this.state.error ? <Alert variant="danger">
                                          {Globals.updatePasswordError}
                                        </Alert> : ""
                                      }
                                      <Form.Row>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                          <Form.Label>Nueva contraseña</Form.Label>
                                          <Form.Control
                                            type="password"
                                            placeholder="Tu Contraseña"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.password && !errors.password}
                                            isInvalid={!!errors.password}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                          </Form.Control.Feedback>
                                          <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                                        </Form.Group>
                                      </Form.Row>
                                      <Form.Row>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                          <Form.Label>Confirma tu nueva Contraseña</Form.Label>
                                          <Form.Control
                                            type="password"
                                            placeholder="Confirma tu Contraseña"
                                            name="passwordConfirmation"
                                            value={values.passwordConfirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                                            isInvalid={!!errors.passwordConfirmation}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.passwordConfirmation}
                                          </Form.Control.Feedback>
                                          <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                                        </Form.Group>
                                      </Form.Row>

                                      <Button variant="primary" type={"submit"}
                                        disabled={!isValid || Object.values(touched).length === 0}>
                                        Actualiza tu password
                                      </Button>
                                    </Form>
                                  )}
                                </Formik>
                              )
                            }
                          }}
                        </Mutation>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={1}/>
                </Row>

              );
            } else {
              return (
                "SIVE"
              );
            }
          }}
        </Query>
      </>
    );
  }
}

export default withSizes(mapSizesToProps)(MyProfile)

