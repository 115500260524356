import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Query} from "react-apollo";
import {RELATED_SITES} from "../graph/query/RelatedSitesQuery";
import Spinner from "react-bootstrap/Spinner";

export default class RelatedSites extends Component {


  render() {
    return (
      <>
        <h3>
          Sitios de Interés
        </h3>
        <hr/>
        <Row>
          <Query query={RELATED_SITES}
            fetchPolicy="cache-and-network"
          >
            {({loading, error, data, fetchMore}) => {
              if (loading) {
                return (
                  <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
              } else {
                return (<>

                    {data.sitioRelacionados.map((site, idx) => (
                      <Col lg={2} xl={2} sm={4} md={4} xs={12} style={{color: "black"}}>
                        <Card style={{height: "400px"}}>
                          <Card.Img variant="top" src={site.foto.url} style={{maxHeight: "140px"}}/>
                          <Card.Body>
                            <Card.Title>{site.nombre}</Card.Title>
                            <Card.Text>
                              {site.descripcion}
                            </Card.Text>
                          </Card.Body>
                          <Card.Body>
                            <Card.Link href={site.link} target="_blank">Ir al sitio</Card.Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </>
                )
              }
            }}
          </Query>
        </Row>
      </>);
  }
}
