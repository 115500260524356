import React, {Component} from "react";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import {FaArrowLeft} from "react-icons/all";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default class Back extends Component {

  goToBack = () => {
    this.props.history.push({
      pathname: '/home',
    })
  };

  render() {
    return (<OverlayTrigger className="float-left"
      overlay={<Tooltip id="tooltip-disabled">Volver</Tooltip>}>
      <Button  variant="primary" className="float-left" onClick={this.goToBack}><FaArrowLeft
        size="25"/></Button>
    </OverlayTrigger>);
  }
}
