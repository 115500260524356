import React from "react";
import Message from "../vehicle/Message";
import Button from "react-bootstrap/Button";
import {FaRedoAlt} from "react-icons/all";
import {isLoggedIn} from "../utils/Constants";

const MessageList = ({ comentarios, userId, vehicle, onLoadMore }) => {
  return (
  <>
    <div className="col-sm-12 my-auto d-flex justify-content-center">
      {
        comentarios.length===0 && !isLoggedIn()?<span>Este vehículo aún no tiene comentarios, puedes comentar como anónimo o puedes<a href="/#/register" className="ml-2">registrarte</a></span>:
        comentarios.length===0 && isLoggedIn()?<span>Este vehículo aún no tiene comentarios, puedes comentar para iniciar la historia</span>:
        isLoggedIn()?<Button onClick={onLoadMore} variant="outline-secondary"><FaRedoAlt/> Cargar mensajes antiguos</Button>:
        <span>Quieres ver toda la historia del vehículo? <a href="/#/register" className="ml-2">Registrate</a></span>
      }
    </div>
    <div className="d-flex flex-column-reverse">
    {comentarios.map((msg, idx) => (
      <Message vehicle={vehicle} message={msg} key={idx} userId={userId}/>
    ))}
    </div>
  </>
)};

export default MessageList;
