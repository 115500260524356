import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Globals, statusTypeList, vehicleTypeList, YupValidations} from "../utils/Constants";
import * as yup from 'yup';
import {setLocale} from 'yup';
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import {Mutation} from "react-apollo";
import BrandLogo from "../utils/BrandLogo";
import {CREATE_VEHICLE_MUTATION} from "../graph/mutation/CreateVehiculeMutation";
import CantProcess from "../utils/CantProcess";
import {Formik} from "formik";
import Back from "../utils/Back";
import Card from "react-bootstrap/Card";

setLocale(YupValidations);

const schema = yup.object().shape({
  vehicleModel: yup.number().required().min(1940).max(new Date().getFullYear()+1),
});

export default class AcceptRegisterVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plate: this.props.plate,
      vehicleType: "AUTO_MECANICO",
      vehicleStatus: "NO_ESTA_EN_VENTA",
      vehicleModel: 0,
      error: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  render() {
    if (this.props.plate) {
      return (
        <Row>
          <Col lg={4} xs={1}/>
          <Col lg={4} xs={10} className="user_card" style={{color: "black"}}>
            <BrandLogo/>
            <Card.Header style={{backgroundColor:"transparent", border: "none"}}><Back history={this.props.history} /></Card.Header>
            <Mutation mutation={CREATE_VEHICLE_MUTATION}
              variables={{
                plate: this.state.plate,
                vehicleType: this.state.vehicleType,
                vehicleStatus: this.state.vehicleStatus,
                vehicleModel: this.state.vehicleModel
              }}
              onError={(error) => {
                this.setState({
                  error: true
                })
              }}
              onCompleted={(data) => {
                this.props.onCompleteFunction();
              }}
            >
              {(createVehicleForm, result) => {
                const {data, loading} = result;

                if (loading) {
                  return (
                    <Spinner animation="border" role="status"><span className="sr-only">Cargando...</span></Spinner>)
                } else {
                  return (
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        vehicleModel: 0,
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,

                      }) => (
                        <Form className="form_container" noValidate onSubmit={event => {
                          handleSubmit();
                          if (!isValid) {
                            event.preventDefault();
                            event.stopPropagation();
                            return;
                          }
                          this.setState({
                            vehicleModel: values.vehicleModel,
                          }, () => {
                            return createVehicleForm();
                          })
                        }}>
                          {data && data.createVehiculo.vehiculo.id ?
                            <>
                              <Alert variant="success">
                                {Globals.createVehicleSuccess} <Spinner animation="border" role="status"><span
                                className="sr-only">Cargando...</span></Spinner>
                              </Alert>
                            </> : this.state.error ? <Alert variant="danger">
                              {Globals.createVehicleError}
                            </Alert> : <Alert variant="info">{Globals.createVehicleInfo}</Alert>
                          }
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Label>Tu placa</Form.Label>
                              <Form.Control
                                type="text"
                                disabled={true}
                                name="placa"
                                value={this.state.plate}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridvehicleModel">
                              <Form.Label>Modelo</Form.Label>
                              <Form.Control
                                type="number"
                                name="vehicleModel"
                                placeholder="Digite el modelo del vehículo"
                                value={values.vehicleModel}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.vehicleModel && !errors.vehicleModel}
                                isInvalid={!!errors.vehicleModel}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.vehicleModel}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Label>¿Qué tipo de vehículo es?</Form.Label>
                              <Form.Control as="select"
                                aria-label="Tipo de Vehículo"
                                aria-describedby="vehicleType"
                                value={this.state.vehicleType}
                                name="vehicleType"
                                onChange={e => this.handleChange(e)}>
                                {vehicleTypeList.map((item, idx) => (
                                  <option key={idx} value={item.key}>{item.text}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Label>¿En qué estado se encuentra el vehículo?</Form.Label>
                              <Form.Control as="select"
                                aria-label="Estado del Vehículo"
                                aria-describedby="vehicleStatus"
                                value={this.state.vehicleStatus}
                                name="vehicleStatus"
                                onChange={e => this.handleChange(e)}>
                                {statusTypeList.map((item, idx) => (
                                  <option key={idx} value={item.key}>{item.text}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>

                          <Button variant="primary" block type={"submit"} disabled={!isValid || Object.values(touched).length === 0}>
                            Registra tu vehículo
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  )
                }
              }}
            </Mutation>

          </Col>
          <Col lg={4} xs={1}/>
        </Row>
      )
    } else {
      return <CantProcess/>
    }
  }
}
