import {gql} from "apollo-boost";

export const SIVE_SCHEMA = gql`
    directive @cacheControl(
        maxAge: Int
        scope: CacheControlScope
    ) on FIELD_DEFINITION | OBJECT | INTERFACE
    type Autorizacion {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        user: UsersPermissionsUser
        vehiculo: Vehiculo
        url: String
        success: Boolean
    }

    type AutorizacionAggregator {
        count: Int
        totalCount: Int
    }

    type AutorizacionConnection {
        values: [Autorizacion]
        groupBy: AutorizacionGroupBy
        aggregate: AutorizacionAggregator
    }

    type AutorizacionConnection_id {
        key: ID
        connection: AutorizacionConnection
    }

    type AutorizacionConnectionCreatedAt {
        key: DateTime
        connection: AutorizacionConnection
    }

    type AutorizacionConnectionId {
        key: ID
        connection: AutorizacionConnection
    }

    type AutorizacionConnectionSuccess {
        key: Boolean
        connection: AutorizacionConnection
    }

    type AutorizacionConnectionUpdatedAt {
        key: DateTime
        connection: AutorizacionConnection
    }

    type AutorizacionConnectionUrl {
        key: String
        connection: AutorizacionConnection
    }

    type AutorizacionConnectionUser {
        key: ID
        connection: AutorizacionConnection
    }

    type AutorizacionConnectionVehiculo {
        key: ID
        connection: AutorizacionConnection
    }

    type AutorizacionGroupBy {
        id: [AutorizacionConnectionId]
        _id: [AutorizacionConnection_id]
        createdAt: [AutorizacionConnectionCreatedAt]
        updatedAt: [AutorizacionConnectionUpdatedAt]
        user: [AutorizacionConnectionUser]
        vehiculo: [AutorizacionConnectionVehiculo]
        url: [AutorizacionConnectionUrl]
        success: [AutorizacionConnectionSuccess]
    }

    input AutorizacionInput {
        user: ID
        vehiculo: ID
        url: String
        success: Boolean
    }

    enum CacheControlScope {
        PUBLIC
        PRIVATE
    }

    type Comentario {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        text: String!
        vehiculo: Vehiculo
        tipoComentario: ENUM_COMENTARIO_TIPOCOMENTARIO!
        user: UsersPermissionsUser
    }

    type ComentarioAggregator {
        count: Int
        totalCount: Int
    }

    type ComentarioConnection {
        values: [Comentario]
        groupBy: ComentarioGroupBy
        aggregate: ComentarioAggregator
    }

    type ComentarioConnection_id {
        key: ID
        connection: ComentarioConnection
    }

    type ComentarioConnectionCreatedAt {
        key: DateTime
        connection: ComentarioConnection
    }

    type ComentarioConnectionId {
        key: ID
        connection: ComentarioConnection
    }

    type ComentarioConnectionText {
        key: String
        connection: ComentarioConnection
    }

    type ComentarioConnectionTipoComentario {
        key: String
        connection: ComentarioConnection
    }

    type ComentarioConnectionUpdatedAt {
        key: DateTime
        connection: ComentarioConnection
    }

    type ComentarioConnectionUser {
        key: ID
        connection: ComentarioConnection
    }

    type ComentarioConnectionVehiculo {
        key: ID
        connection: ComentarioConnection
    }

    type ComentarioGroupBy {
        id: [ComentarioConnectionId]
        _id: [ComentarioConnection_id]
        createdAt: [ComentarioConnectionCreatedAt]
        updatedAt: [ComentarioConnectionUpdatedAt]
        text: [ComentarioConnectionText]
        vehiculo: [ComentarioConnectionVehiculo]
        tipoComentario: [ComentarioConnectionTipoComentario]
        user: [ComentarioConnectionUser]
    }

    input ComentarioInput {
        text: String!
        vehiculo: ID
        tipoComentario: ENUM_COMENTARIO_TIPOCOMENTARIO!
        user: ID
    }

    input Confirmation {
        token: String!
        securityCode: String!
    }

    input createAutorizacionInput {
        data: AutorizacionInput
    }

    type createAutorizacionPayload {
        autorizacion: Autorizacion
    }

    input createComentarioInput {
        data: ComentarioInput
    }

    type createComentarioPayload {
        comentario: Comentario
    }

    input createLogoInput {
        data: LogoInput
    }

    type createLogoPayload {
        logo: Logo
    }

    input createMensajeContactoInput {
        data: MensajeContactoInput
    }

    type createMensajeContactoPayload {
        mensajeContacto: MensajeContacto
    }

    input createNosotroInput {
        data: NosotroInput
    }

    type createNosotroPayload {
        nosotro: Nosotros
    }

    input createOtpInput {
        data: OtpInput
    }

    type createOtpPayload {
        otp: Otp
    }

    input createPageInput {
        data: PageInput
    }

    type createPagePayload {
        page: Page
    }

    input createRoleInput {
        data: RoleInput
    }

    type createRolePayload {
        role: UsersPermissionsRole
    }

    input createSitioRelacionadoInput {
        data: SitioRelacionadoInput
    }

    type createSitioRelacionadoPayload {
        sitioRelacionado: SitioRelacionado
    }

    input createUserInput {
        data: UserInput
    }

    type createUserPayload {
        user: UsersPermissionsUser
    }

    input createVehiculoInput {
        data: VehiculoInput
    }

    type createVehiculoPayload {
        vehiculo: Vehiculo
    }

    scalar Date

    scalar DateTime

    input deleteAutorizacionInput {
        where: InputID
    }

    type deleteAutorizacionPayload {
        autorizacion: Autorizacion
    }

    input deleteComentarioInput {
        where: InputID
    }

    type deleteComentarioPayload {
        comentario: Comentario
    }

    input deleteLogoInput {
        where: InputID
    }

    type deleteLogoPayload {
        logo: Logo
    }

    input deleteMensajeContactoInput {
        where: InputID
    }

    type deleteMensajeContactoPayload {
        mensajeContacto: MensajeContacto
    }

    input deleteNosotroInput {
        where: InputID
    }

    type deleteNosotroPayload {
        nosotro: Nosotros
    }

    input deleteOtpInput {
        where: InputID
    }

    type deleteOtpPayload {
        otp: Otp
    }

    input deletePageInput {
        where: InputID
    }

    type deletePagePayload {
        page: Page
    }

    input deleteRoleInput {
        where: InputID
    }

    type deleteRolePayload {
        role: UsersPermissionsRole
    }

    input deleteSitioRelacionadoInput {
        where: InputID
    }

    type deleteSitioRelacionadoPayload {
        sitioRelacionado: SitioRelacionado
    }

    input deleteUserInput {
        where: InputID
    }

    type deleteUserPayload {
        user: UsersPermissionsUser
    }

    input deleteVehiculoInput {
        where: InputID
    }

    type deleteVehiculoPayload {
        vehiculo: Vehiculo
    }

    input editAutorizacionInput {
        user: ID
        vehiculo: ID
        url: String
        success: Boolean
    }

    input editComentarioInput {
        text: String
        vehiculo: ID
        tipoComentario: ENUM_COMENTARIO_TIPOCOMENTARIO
        user: ID
    }

    input editFileInput {
        name: String
        alternativeText: String
        caption: String
        width: Int
        height: Int
        formats: JSON
        hash: String
        ext: String
        mime: String
        size: Float
        url: String
        previewUrl: String
        provider: String
        provider_metadata: JSON
        related: [ID]
    }

    input editLogoInput {
        file: ID
        logoType: ENUM_LOGO_LOGOTYPE
    }

    input editMensajeContactoInput {
        nombre: String
        mensaje: String
        correo: String
    }

    input editNosotroInput {
        titulo: String
        texto: String
        tipo: ENUM_NOSOTROS_TIPO
        foto: ID
        enlace: String
        textoEnlace: String
    }

    input editOtpInput {
        code: String
        user: ID
        phoneNumber: String
    }

    input editPageInput {
        titulo: String
        introduccion: String
    }

    input editRoleInput {
        name: String
        description: String
        type: String
        permissions: [ID]
        users: [ID]
    }

    input editSitioRelacionadoInput {
        nombre: String
        descripcion: String
        link: String
        foto: ID
    }

    input editUserInput {
        username: String
        email: String
        provider: String
        password: String
        resetPasswordToken: String
        confirmed: Boolean
        blocked: Boolean
        role: ID
        photo: ID
        name: String
        lastName: String
        celular: String
        corporate: Boolean
    }

    input editVehiculoInput {
        placa: String
        tipo: ENUM_VEHICULO_TIPO
        ciudadMatricula: String
        fotos: [ID]
        marca: String
        referencia: String
        modelo: Int
        color: String
        comentarios: [ID]
        estado: ENUM_VEHICULO_ESTADO
        vin: String
        servicio: ENUM_VEHICULO_SERVICIO
        combustible: ENUM_VEHICULO_COMBUSTIBLE
    }

    enum ENUM_COMENTARIO_TIPOCOMENTARIO {
        NORMAL
        ROBO
        CERTIFICACION
        SISTEMA
    }

    enum ENUM_LOGO_LOGOTYPE {
        mainLogo
        secondaryLogo
        justLogo
    }

    enum ENUM_NOSOTROS_TIPO {
        PRINCIPAL
        SECUNDARIO
    }

    enum ENUM_VEHICULO_COMBUSTIBLE {
        GASOLINA
        GNV
        DIESEL
        GASOLINA_GAS
        ELECTRICO
        HIDROGENO
        ETANOL
    }

    enum ENUM_VEHICULO_ESTADO {
        NO_ESTA_EN_VENTA
        PROCESO_DE_VENTA
        PATIOS_INMOVILIZADO
        PERDIDA_TOTAL
        PLEITO_LEGAL
        ROBADO
    }

    enum ENUM_VEHICULO_SERVICIO {
        PARTICULAR
        PUBLICO
        DIPLOMATICO
        OFICIAL
    }

    enum ENUM_VEHICULO_TIPO {
        AUTOMOVIL
        BUS
        BUSETA
        CAMION
        CAMIONETA
        CAMPERO
        MICROBUS
        TRACTOCAMION
        MOTOCICLETA
        MOTOCARRO
        MOTOTRICICLO
        CUATRIMOTO
        VOLQUETA
    }

    input FileInput {
        name: String!
        alternativeText: String
        caption: String
        width: Int
        height: Int
        formats: JSON
        hash: String!
        ext: String
        mime: String!
        size: Float!
        url: String!
        previewUrl: String
        provider: String!
        provider_metadata: JSON
        related: [ID]
    }

    input InputID {
        id: ID!
    }

    scalar JSON

    type Logo {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        file: UploadFile
        logoType: ENUM_LOGO_LOGOTYPE!
    }

    type LogoAggregator {
        count: Int
        totalCount: Int
    }

    type LogoConnection {
        values: [Logo]
        groupBy: LogoGroupBy
        aggregate: LogoAggregator
    }

    type LogoConnection_id {
        key: ID
        connection: LogoConnection
    }

    type LogoConnectionCreatedAt {
        key: DateTime
        connection: LogoConnection
    }

    type LogoConnectionFile {
        key: ID
        connection: LogoConnection
    }

    type LogoConnectionId {
        key: ID
        connection: LogoConnection
    }

    type LogoConnectionLogoType {
        key: String
        connection: LogoConnection
    }

    type LogoConnectionUpdatedAt {
        key: DateTime
        connection: LogoConnection
    }

    type LogoGroupBy {
        id: [LogoConnectionId]
        _id: [LogoConnection_id]
        createdAt: [LogoConnectionCreatedAt]
        updatedAt: [LogoConnectionUpdatedAt]
        file: [LogoConnectionFile]
        logoType: [LogoConnectionLogoType]
    }

    input LogoInput {
        file: ID
        logoType: ENUM_LOGO_LOGOTYPE!
    }

    scalar Long

    type MensajeContacto {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        nombre: String!
        mensaje: String!
        correo: String!
    }

    type MensajeContactoAggregator {
        count: Int
        totalCount: Int
    }

    type MensajeContactoConnection {
        values: [MensajeContacto]
        groupBy: MensajeContactoGroupBy
        aggregate: MensajeContactoAggregator
    }

    type MensajeContactoConnection_id {
        key: ID
        connection: MensajeContactoConnection
    }

    type MensajeContactoConnectionCorreo {
        key: String
        connection: MensajeContactoConnection
    }

    type MensajeContactoConnectionCreatedAt {
        key: DateTime
        connection: MensajeContactoConnection
    }

    type MensajeContactoConnectionId {
        key: ID
        connection: MensajeContactoConnection
    }

    type MensajeContactoConnectionMensaje {
        key: String
        connection: MensajeContactoConnection
    }

    type MensajeContactoConnectionNombre {
        key: String
        connection: MensajeContactoConnection
    }

    type MensajeContactoConnectionUpdatedAt {
        key: DateTime
        connection: MensajeContactoConnection
    }

    type MensajeContactoGroupBy {
        id: [MensajeContactoConnectionId]
        _id: [MensajeContactoConnection_id]
        createdAt: [MensajeContactoConnectionCreatedAt]
        updatedAt: [MensajeContactoConnectionUpdatedAt]
        nombre: [MensajeContactoConnectionNombre]
        mensaje: [MensajeContactoConnectionMensaje]
        correo: [MensajeContactoConnectionCorreo]
    }

    input MensajeContactoInput {
        nombre: String!
        mensaje: String!
        correo: String!
    }

    union Morph =
        Result
        | UsersPermissionsMe
        | UsersPermissionsMeRole
        | UsersPermissionsLoginPayload
        | UserPermissionsPasswordPayload
        | Autorizacion
        | AutorizacionConnection
        | AutorizacionAggregator
        | AutorizacionGroupBy
        | AutorizacionConnectionId
        | AutorizacionConnection_id
        | AutorizacionConnectionCreatedAt
        | AutorizacionConnectionUpdatedAt
        | AutorizacionConnectionUser
        | AutorizacionConnectionVehiculo
        | AutorizacionConnectionUrl
        | AutorizacionConnectionSuccess
        | createAutorizacionPayload
        | updateAutorizacionPayload
        | deleteAutorizacionPayload
        | Comentario
        | ComentarioConnection
        | ComentarioAggregator
        | ComentarioGroupBy
        | ComentarioConnectionId
        | ComentarioConnection_id
        | ComentarioConnectionCreatedAt
        | ComentarioConnectionUpdatedAt
        | ComentarioConnectionText
        | ComentarioConnectionVehiculo
        | ComentarioConnectionTipoComentario
        | ComentarioConnectionUser
        | createComentarioPayload
        | updateComentarioPayload
        | deleteComentarioPayload
        | Logo
        | LogoConnection
        | LogoAggregator
        | LogoGroupBy
        | LogoConnectionId
        | LogoConnection_id
        | LogoConnectionCreatedAt
        | LogoConnectionUpdatedAt
        | LogoConnectionFile
        | LogoConnectionLogoType
        | createLogoPayload
        | updateLogoPayload
        | deleteLogoPayload
        | MensajeContacto
        | MensajeContactoConnection
        | MensajeContactoAggregator
        | MensajeContactoGroupBy
        | MensajeContactoConnectionId
        | MensajeContactoConnection_id
        | MensajeContactoConnectionCreatedAt
        | MensajeContactoConnectionUpdatedAt
        | MensajeContactoConnectionNombre
        | MensajeContactoConnectionMensaje
        | MensajeContactoConnectionCorreo
        | createMensajeContactoPayload
        | updateMensajeContactoPayload
        | deleteMensajeContactoPayload
        | Nosotros
        | NosotrosConnection
        | NosotrosAggregator
        | NosotrosGroupBy
        | NosotrosConnectionId
        | NosotrosConnection_id
        | NosotrosConnectionCreatedAt
        | NosotrosConnectionUpdatedAt
        | NosotrosConnectionTitulo
        | NosotrosConnectionTexto
        | NosotrosConnectionTipo
        | NosotrosConnectionFoto
        | NosotrosConnectionEnlace
        | NosotrosConnectionTextoEnlace
        | createNosotroPayload
        | updateNosotroPayload
        | deleteNosotroPayload
        | Otp
        | OtpConnection
        | OtpAggregator
        | OtpGroupBy
        | OtpConnectionId
        | OtpConnection_id
        | OtpConnectionCreatedAt
        | OtpConnectionUpdatedAt
        | OtpConnectionCode
        | OtpConnectionUser
        | OtpConnectionPhoneNumber
        | createOtpPayload
        | updateOtpPayload
        | deleteOtpPayload
        | Page
        | PageConnection
        | PageAggregator
        | PageGroupBy
        | PageConnectionId
        | PageConnection_id
        | PageConnectionCreatedAt
        | PageConnectionUpdatedAt
        | PageConnectionTitulo
        | PageConnectionIntroduccion
        | createPagePayload
        | updatePagePayload
        | deletePagePayload
        | SitioRelacionado
        | SitioRelacionadoConnection
        | SitioRelacionadoAggregator
        | SitioRelacionadoGroupBy
        | SitioRelacionadoConnectionId
        | SitioRelacionadoConnection_id
        | SitioRelacionadoConnectionCreatedAt
        | SitioRelacionadoConnectionUpdatedAt
        | SitioRelacionadoConnectionNombre
        | SitioRelacionadoConnectionDescripcion
        | SitioRelacionadoConnectionLink
        | SitioRelacionadoConnectionFoto
        | createSitioRelacionadoPayload
        | updateSitioRelacionadoPayload
        | deleteSitioRelacionadoPayload
        | Vehiculo
        | VehiculoConnection
        | VehiculoAggregator
        | VehiculoAggregatorSum
        | VehiculoAggregatorAvg
        | VehiculoAggregatorMin
        | VehiculoAggregatorMax
        | VehiculoGroupBy
        | VehiculoConnectionId
        | VehiculoConnection_id
        | VehiculoConnectionCreatedAt
        | VehiculoConnectionUpdatedAt
        | VehiculoConnectionPlaca
        | VehiculoConnectionTipo
        | VehiculoConnectionCiudadMatricula
        | VehiculoConnectionMarca
        | VehiculoConnectionReferencia
        | VehiculoConnectionModelo
        | VehiculoConnectionColor
        | VehiculoConnectionEstado
        | VehiculoConnectionVin
        | VehiculoConnectionServicio
        | VehiculoConnectionCombustible
        | createVehiculoPayload
        | updateVehiculoPayload
        | deleteVehiculoPayload
        | UploadFile
        | UploadFileConnection
        | UploadFileAggregator
        | UploadFileAggregatorSum
        | UploadFileAggregatorAvg
        | UploadFileAggregatorMin
        | UploadFileAggregatorMax
        | UploadFileGroupBy
        | UploadFileConnectionId
        | UploadFileConnection_id
        | UploadFileConnectionCreatedAt
        | UploadFileConnectionUpdatedAt
        | UploadFileConnectionName
        | UploadFileConnectionAlternativeText
        | UploadFileConnectionCaption
        | UploadFileConnectionWidth
        | UploadFileConnectionHeight
        | UploadFileConnectionFormats
        | UploadFileConnectionHash
        | UploadFileConnectionExt
        | UploadFileConnectionMime
        | UploadFileConnectionSize
        | UploadFileConnectionUrl
        | UploadFileConnectionPreviewUrl
        | UploadFileConnectionProvider
        | UploadFileConnectionProvider_metadata
        | UsersPermissionsPermission
        | UsersPermissionsRole
        | UsersPermissionsRoleConnection
        | UsersPermissionsRoleAggregator
        | UsersPermissionsRoleGroupBy
        | UsersPermissionsRoleConnectionId
        | UsersPermissionsRoleConnection_id
        | UsersPermissionsRoleConnectionCreatedAt
        | UsersPermissionsRoleConnectionUpdatedAt
        | UsersPermissionsRoleConnectionName
        | UsersPermissionsRoleConnectionDescription
        | UsersPermissionsRoleConnectionType
        | createRolePayload
        | updateRolePayload
        | deleteRolePayload
        | UsersPermissionsUser
        | UsersPermissionsUserConnection
        | UsersPermissionsUserAggregator
        | UsersPermissionsUserGroupBy
        | UsersPermissionsUserConnectionId
        | UsersPermissionsUserConnection_id
        | UsersPermissionsUserConnectionCreatedAt
        | UsersPermissionsUserConnectionUpdatedAt
        | UsersPermissionsUserConnectionUsername
        | UsersPermissionsUserConnectionEmail
        | UsersPermissionsUserConnectionProvider
        | UsersPermissionsUserConnectionConfirmed
        | UsersPermissionsUserConnectionBlocked
        | UsersPermissionsUserConnectionRole
        | UsersPermissionsUserConnectionPhoto
        | UsersPermissionsUserConnectionName
        | UsersPermissionsUserConnectionLastName
        | UsersPermissionsUserConnectionCelular
        | UsersPermissionsUserConnectionCorporate
        | createUserPayload
        | updateUserPayload
        | deleteUserPayload
    type Mutation {
        createAutorizacion(input: createAutorizacionInput): createAutorizacionPayload
        updateAutorizacion(input: updateAutorizacionInput): updateAutorizacionPayload
        deleteAutorizacion(input: deleteAutorizacionInput): deleteAutorizacionPayload
        createComentario(input: createComentarioInput): createComentarioPayload
        updateComentario(input: updateComentarioInput): updateComentarioPayload
        deleteComentario(input: deleteComentarioInput): deleteComentarioPayload
        createLogo(input: createLogoInput): createLogoPayload
        updateLogo(input: updateLogoInput): updateLogoPayload
        deleteLogo(input: deleteLogoInput): deleteLogoPayload
        createMensajeContacto(
            input: createMensajeContactoInput
        ): createMensajeContactoPayload
        updateMensajeContacto(
            input: updateMensajeContactoInput
        ): updateMensajeContactoPayload
        deleteMensajeContacto(
            input: deleteMensajeContactoInput
        ): deleteMensajeContactoPayload
        createNosotro(input: createNosotroInput): createNosotroPayload
        updateNosotro(input: updateNosotroInput): updateNosotroPayload
        deleteNosotro(input: deleteNosotroInput): deleteNosotroPayload
        createOtp(input: createOtpInput): createOtpPayload
        updateOtp(input: updateOtpInput): updateOtpPayload
        deleteOtp(input: deleteOtpInput): deleteOtpPayload
        createPage(input: createPageInput): createPagePayload
        updatePage(input: updatePageInput): updatePagePayload
        deletePage(input: deletePageInput): deletePagePayload
        createSitioRelacionado(
            input: createSitioRelacionadoInput
        ): createSitioRelacionadoPayload
        updateSitioRelacionado(
            input: updateSitioRelacionadoInput
        ): updateSitioRelacionadoPayload
        deleteSitioRelacionado(
            input: deleteSitioRelacionadoInput
        ): deleteSitioRelacionadoPayload
        createVehiculo(input: createVehiculoInput): createVehiculoPayload
        updateVehiculo(input: updateVehiculoInput): updateVehiculoPayload
        deleteVehiculo(input: deleteVehiculoInput): deleteVehiculoPayload
        createRole(input: createRoleInput): createRolePayload
        updateRole(input: updateRoleInput): updateRolePayload
        deleteRole(input: deleteRoleInput): deleteRolePayload
        createUser(input: createUserInput): createUserPayload
        updateUser(input: updateUserInput): updateUserPayload
        deleteUser(input: deleteUserInput): deleteUserPayload
        siveRegistration(registration: Registration!): Result!
        siveConfirmation(confirmation: Confirmation!): Result!
        rotateImage(url: String!): String!
        upload(
            refId: ID
            ref: String
            field: String
            source: String
            file: Upload!
        ): UploadFile!
        multipleUpload(
            refId: ID
            ref: String
            field: String
            source: String
            files: [Upload]!
        ): [UploadFile]!
        login(input: UsersPermissionsLoginInput!): UsersPermissionsLoginPayload!
        register(input: UsersPermissionsRegisterInput!): UsersPermissionsLoginPayload!
        forgotPassword(email: String!): UserPermissionsPasswordPayload
        resetPassword(
            password: String!
            passwordConfirmation: String!
            code: String!
        ): UsersPermissionsLoginPayload
        emailConfirmation(confirmation: String!): UsersPermissionsLoginPayload
    }

    input NosotroInput {
        titulo: String!
        texto: String!
        tipo: ENUM_NOSOTROS_TIPO!
        foto: ID
        enlace: String
        textoEnlace: String
    }

    type Nosotros {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        titulo: String!
        texto: String!
        tipo: ENUM_NOSOTROS_TIPO!
        foto: UploadFile
        enlace: String
        textoEnlace: String
    }

    type NosotrosAggregator {
        count: Int
        totalCount: Int
    }

    type NosotrosConnection {
        values: [Nosotros]
        groupBy: NosotrosGroupBy
        aggregate: NosotrosAggregator
    }

    type NosotrosConnection_id {
        key: ID
        connection: NosotrosConnection
    }

    type NosotrosConnectionCreatedAt {
        key: DateTime
        connection: NosotrosConnection
    }

    type NosotrosConnectionEnlace {
        key: String
        connection: NosotrosConnection
    }

    type NosotrosConnectionFoto {
        key: ID
        connection: NosotrosConnection
    }

    type NosotrosConnectionId {
        key: ID
        connection: NosotrosConnection
    }

    type NosotrosConnectionTexto {
        key: String
        connection: NosotrosConnection
    }

    type NosotrosConnectionTextoEnlace {
        key: String
        connection: NosotrosConnection
    }

    type NosotrosConnectionTipo {
        key: String
        connection: NosotrosConnection
    }

    type NosotrosConnectionTitulo {
        key: String
        connection: NosotrosConnection
    }

    type NosotrosConnectionUpdatedAt {
        key: DateTime
        connection: NosotrosConnection
    }

    type NosotrosGroupBy {
        id: [NosotrosConnectionId]
        _id: [NosotrosConnection_id]
        createdAt: [NosotrosConnectionCreatedAt]
        updatedAt: [NosotrosConnectionUpdatedAt]
        titulo: [NosotrosConnectionTitulo]
        texto: [NosotrosConnectionTexto]
        tipo: [NosotrosConnectionTipo]
        foto: [NosotrosConnectionFoto]
        enlace: [NosotrosConnectionEnlace]
        textoEnlace: [NosotrosConnectionTextoEnlace]
    }

    type Otp {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        code: String
        user: UsersPermissionsUser
        phoneNumber: String
    }

    type OtpAggregator {
        count: Int
        totalCount: Int
    }

    type OtpConnection {
        values: [Otp]
        groupBy: OtpGroupBy
        aggregate: OtpAggregator
    }

    type OtpConnection_id {
        key: ID
        connection: OtpConnection
    }

    type OtpConnectionCode {
        key: String
        connection: OtpConnection
    }

    type OtpConnectionCreatedAt {
        key: DateTime
        connection: OtpConnection
    }

    type OtpConnectionId {
        key: ID
        connection: OtpConnection
    }

    type OtpConnectionPhoneNumber {
        key: String
        connection: OtpConnection
    }

    type OtpConnectionUpdatedAt {
        key: DateTime
        connection: OtpConnection
    }

    type OtpConnectionUser {
        key: ID
        connection: OtpConnection
    }

    type OtpGroupBy {
        id: [OtpConnectionId]
        _id: [OtpConnection_id]
        createdAt: [OtpConnectionCreatedAt]
        updatedAt: [OtpConnectionUpdatedAt]
        code: [OtpConnectionCode]
        user: [OtpConnectionUser]
        phoneNumber: [OtpConnectionPhoneNumber]
    }

    input OtpInput {
        code: String
        user: ID
        phoneNumber: String
    }

    type Page {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        titulo: String
        introduccion: String!
    }

    type PageAggregator {
        count: Int
        totalCount: Int
    }

    type PageConnection {
        values: [Page]
        groupBy: PageGroupBy
        aggregate: PageAggregator
    }

    type PageConnection_id {
        key: ID
        connection: PageConnection
    }

    type PageConnectionCreatedAt {
        key: DateTime
        connection: PageConnection
    }

    type PageConnectionId {
        key: ID
        connection: PageConnection
    }

    type PageConnectionIntroduccion {
        key: String
        connection: PageConnection
    }

    type PageConnectionTitulo {
        key: String
        connection: PageConnection
    }

    type PageConnectionUpdatedAt {
        key: DateTime
        connection: PageConnection
    }

    type PageGroupBy {
        id: [PageConnectionId]
        _id: [PageConnection_id]
        createdAt: [PageConnectionCreatedAt]
        updatedAt: [PageConnectionUpdatedAt]
        titulo: [PageConnectionTitulo]
        introduccion: [PageConnectionIntroduccion]
    }

    input PageInput {
        titulo: String
        introduccion: String!
    }

    type Query {
        autorizacion(id: ID!): Autorizacion
        autorizacions(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): [Autorizacion]
        autorizacionsConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): AutorizacionConnection
        comentario(id: ID!): Comentario
        comentarios(sort: String, limit: Int, start: Int, where: JSON): [Comentario]
        comentariosConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): ComentarioConnection
        logo(id: ID!): Logo
        logos(sort: String, limit: Int, start: Int, where: JSON): [Logo]
        logosConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): LogoConnection
        mensajeContacto(id: ID!): MensajeContacto
        mensajeContactos(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): [MensajeContacto]
        mensajeContactosConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): MensajeContactoConnection
        nosotro(id: ID!): Nosotros
        nosotros(sort: String, limit: Int, start: Int, where: JSON): [Nosotros]
        nosotrosConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): NosotrosConnection
        otp(id: ID!): Otp
        otps(sort: String, limit: Int, start: Int, where: JSON): [Otp]
        otpsConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): OtpConnection
        page(id: ID!): Page
        pages(sort: String, limit: Int, start: Int, where: JSON): [Page]
        pagesConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): PageConnection
        sitioRelacionado(id: ID!): SitioRelacionado
        sitioRelacionados(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): [SitioRelacionado]
        sitioRelacionadosConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): SitioRelacionadoConnection
        vehiculo(id: ID!): Vehiculo
        vehiculos(sort: String, limit: Int, start: Int, where: JSON): [Vehiculo]
        vehiculosConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): VehiculoConnection
        files(sort: String, limit: Int, start: Int, where: JSON): [UploadFile]
        filesConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): UploadFileConnection
        role(id: ID!): UsersPermissionsRole
        roles(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): [UsersPermissionsRole]
        rolesConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): UsersPermissionsRoleConnection
        user(id: ID!): UsersPermissionsUser
        users(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): [UsersPermissionsUser]
        usersConnection(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): UsersPermissionsUserConnection
        me: UsersPermissionsMe
    }

    input Registration {
        name: String!
        lastName: String!
        email: String!
        cellphone: String!
        password: String!
    }

    type Result {
        token: String
        status: String!
        userId: String
    }

    input RoleInput {
        name: String!
        description: String
        type: String
        permissions: [ID]
        users: [ID]
    }

    type SitioRelacionado {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        nombre: String!
        descripcion: String!
        link: String!
        foto: UploadFile
    }

    type SitioRelacionadoAggregator {
        count: Int
        totalCount: Int
    }

    type SitioRelacionadoConnection {
        values: [SitioRelacionado]
        groupBy: SitioRelacionadoGroupBy
        aggregate: SitioRelacionadoAggregator
    }

    type SitioRelacionadoConnection_id {
        key: ID
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoConnectionCreatedAt {
        key: DateTime
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoConnectionDescripcion {
        key: String
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoConnectionFoto {
        key: ID
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoConnectionId {
        key: ID
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoConnectionLink {
        key: String
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoConnectionNombre {
        key: String
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoConnectionUpdatedAt {
        key: DateTime
        connection: SitioRelacionadoConnection
    }

    type SitioRelacionadoGroupBy {
        id: [SitioRelacionadoConnectionId]
        _id: [SitioRelacionadoConnection_id]
        createdAt: [SitioRelacionadoConnectionCreatedAt]
        updatedAt: [SitioRelacionadoConnectionUpdatedAt]
        nombre: [SitioRelacionadoConnectionNombre]
        descripcion: [SitioRelacionadoConnectionDescripcion]
        link: [SitioRelacionadoConnectionLink]
        foto: [SitioRelacionadoConnectionFoto]
    }

    input SitioRelacionadoInput {
        nombre: String!
        descripcion: String!
        link: String!
        foto: ID
    }

    scalar Time

    input updateAutorizacionInput {
        where: InputID
        data: editAutorizacionInput
    }

    type updateAutorizacionPayload {
        autorizacion: Autorizacion
    }

    input updateComentarioInput {
        where: InputID
        data: editComentarioInput
    }

    type updateComentarioPayload {
        comentario: Comentario
    }

    input updateLogoInput {
        where: InputID
        data: editLogoInput
    }

    type updateLogoPayload {
        logo: Logo
    }

    input updateMensajeContactoInput {
        where: InputID
        data: editMensajeContactoInput
    }

    type updateMensajeContactoPayload {
        mensajeContacto: MensajeContacto
    }

    input updateNosotroInput {
        where: InputID
        data: editNosotroInput
    }

    type updateNosotroPayload {
        nosotro: Nosotros
    }

    input updateOtpInput {
        where: InputID
        data: editOtpInput
    }

    type updateOtpPayload {
        otp: Otp
    }

    input updatePageInput {
        where: InputID
        data: editPageInput
    }

    type updatePagePayload {
        page: Page
    }

    input updateRoleInput {
        where: InputID
        data: editRoleInput
    }

    type updateRolePayload {
        role: UsersPermissionsRole
    }

    input updateSitioRelacionadoInput {
        where: InputID
        data: editSitioRelacionadoInput
    }

    type updateSitioRelacionadoPayload {
        sitioRelacionado: SitioRelacionado
    }

    input updateUserInput {
        where: InputID
        data: editUserInput
    }

    type updateUserPayload {
        user: UsersPermissionsUser
    }

    input updateVehiculoInput {
        where: InputID
        data: editVehiculoInput
    }

    type updateVehiculoPayload {
        vehiculo: Vehiculo
    }

    scalar Upload

    type UploadFile {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        name: String!
        alternativeText: String
        caption: String
        width: Int
        height: Int
        formats: JSON
        hash: String!
        ext: String
        mime: String!
        size: Float!
        url: String!
        previewUrl: String
        provider: String!
        provider_metadata: JSON
        related(sort: String, limit: Int, start: Int, where: JSON): [Morph]
    }

    type UploadFileAggregator {
        count: Int
        totalCount: Int
        sum: UploadFileAggregatorSum
        avg: UploadFileAggregatorAvg
        min: UploadFileAggregatorMin
        max: UploadFileAggregatorMax
    }

    type UploadFileAggregatorAvg {
        width: Float
        height: Float
        size: Float
    }

    type UploadFileAggregatorMax {
        width: Float
        height: Float
        size: Float
    }

    type UploadFileAggregatorMin {
        width: Float
        height: Float
        size: Float
    }

    type UploadFileAggregatorSum {
        width: Float
        height: Float
        size: Float
    }

    type UploadFileConnection {
        values: [UploadFile]
        groupBy: UploadFileGroupBy
        aggregate: UploadFileAggregator
    }

    type UploadFileConnection_id {
        key: ID
        connection: UploadFileConnection
    }

    type UploadFileConnectionAlternativeText {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionCaption {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionCreatedAt {
        key: DateTime
        connection: UploadFileConnection
    }

    type UploadFileConnectionExt {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionFormats {
        key: JSON
        connection: UploadFileConnection
    }

    type UploadFileConnectionHash {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionHeight {
        key: Int
        connection: UploadFileConnection
    }

    type UploadFileConnectionId {
        key: ID
        connection: UploadFileConnection
    }

    type UploadFileConnectionMime {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionName {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionPreviewUrl {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionProvider {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionProvider_metadata {
        key: JSON
        connection: UploadFileConnection
    }

    type UploadFileConnectionSize {
        key: Float
        connection: UploadFileConnection
    }

    type UploadFileConnectionUpdatedAt {
        key: DateTime
        connection: UploadFileConnection
    }

    type UploadFileConnectionUrl {
        key: String
        connection: UploadFileConnection
    }

    type UploadFileConnectionWidth {
        key: Int
        connection: UploadFileConnection
    }

    type UploadFileGroupBy {
        id: [UploadFileConnectionId]
        _id: [UploadFileConnection_id]
        createdAt: [UploadFileConnectionCreatedAt]
        updatedAt: [UploadFileConnectionUpdatedAt]
        name: [UploadFileConnectionName]
        alternativeText: [UploadFileConnectionAlternativeText]
        caption: [UploadFileConnectionCaption]
        width: [UploadFileConnectionWidth]
        height: [UploadFileConnectionHeight]
        formats: [UploadFileConnectionFormats]
        hash: [UploadFileConnectionHash]
        ext: [UploadFileConnectionExt]
        mime: [UploadFileConnectionMime]
        size: [UploadFileConnectionSize]
        url: [UploadFileConnectionUrl]
        previewUrl: [UploadFileConnectionPreviewUrl]
        provider: [UploadFileConnectionProvider]
        provider_metadata: [UploadFileConnectionProvider_metadata]
    }

    input UserInput {
        username: String!
        email: String!
        provider: String
        password: String
        resetPasswordToken: String
        confirmed: Boolean
        blocked: Boolean
        role: ID
        photo: ID
        name: String
        lastName: String
        celular: String
        corporate: Boolean
    }

    type UserPermissionsPasswordPayload {
        ok: Boolean!
    }

    input UsersPermissionsLoginInput {
        identifier: String!
        password: String!
        provider: String = "local"
    }

    type UsersPermissionsLoginPayload {
        jwt: String!
        user: UsersPermissionsMe!
    }

    type UsersPermissionsMe {
        id: ID!
        username: String!
        email: String!
        confirmed: Boolean
        blocked: Boolean
        role: UsersPermissionsMeRole
    }

    type UsersPermissionsMeRole {
        id: ID!
        name: String!
        description: String
        type: String
    }

    type UsersPermissionsPermission {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        type: String!
        controller: String!
        action: String!
        enabled: Boolean!
        policy: String
        role: UsersPermissionsRole
    }

    input UsersPermissionsRegisterInput {
        username: String!
        email: String!
        password: String!
    }

    type UsersPermissionsRole {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        name: String!
        description: String
        type: String
        permissions(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): [UsersPermissionsPermission]
        users(
            sort: String
            limit: Int
            start: Int
            where: JSON
        ): [UsersPermissionsUser]
    }

    type UsersPermissionsRoleAggregator {
        count: Int
        totalCount: Int
    }

    type UsersPermissionsRoleConnection {
        values: [UsersPermissionsRole]
        groupBy: UsersPermissionsRoleGroupBy
        aggregate: UsersPermissionsRoleAggregator
    }

    type UsersPermissionsRoleConnection_id {
        key: ID
        connection: UsersPermissionsRoleConnection
    }

    type UsersPermissionsRoleConnectionCreatedAt {
        key: DateTime
        connection: UsersPermissionsRoleConnection
    }

    type UsersPermissionsRoleConnectionDescription {
        key: String
        connection: UsersPermissionsRoleConnection
    }

    type UsersPermissionsRoleConnectionId {
        key: ID
        connection: UsersPermissionsRoleConnection
    }

    type UsersPermissionsRoleConnectionName {
        key: String
        connection: UsersPermissionsRoleConnection
    }

    type UsersPermissionsRoleConnectionType {
        key: String
        connection: UsersPermissionsRoleConnection
    }

    type UsersPermissionsRoleConnectionUpdatedAt {
        key: DateTime
        connection: UsersPermissionsRoleConnection
    }

    type UsersPermissionsRoleGroupBy {
        id: [UsersPermissionsRoleConnectionId]
        _id: [UsersPermissionsRoleConnection_id]
        createdAt: [UsersPermissionsRoleConnectionCreatedAt]
        updatedAt: [UsersPermissionsRoleConnectionUpdatedAt]
        name: [UsersPermissionsRoleConnectionName]
        description: [UsersPermissionsRoleConnectionDescription]
        type: [UsersPermissionsRoleConnectionType]
    }

    type UsersPermissionsUser {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        username: String!
        email: String!
        provider: String
        confirmed: Boolean
        blocked: Boolean
        role: UsersPermissionsRole
        photo: UploadFile
        name: String
        lastName: String
        celular: String
        corporate: Boolean
    }

    type UsersPermissionsUserAggregator {
        count: Int
        totalCount: Int
    }

    type UsersPermissionsUserConnection {
        values: [UsersPermissionsUser]
        groupBy: UsersPermissionsUserGroupBy
        aggregate: UsersPermissionsUserAggregator
    }

    type UsersPermissionsUserConnection_id {
        key: ID
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionBlocked {
        key: Boolean
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionCelular {
        key: String
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionConfirmed {
        key: Boolean
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionCorporate {
        key: Boolean
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionCreatedAt {
        key: DateTime
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionEmail {
        key: String
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionId {
        key: ID
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionLastName {
        key: String
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionName {
        key: String
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionPhoto {
        key: ID
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionProvider {
        key: String
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionRole {
        key: ID
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionUpdatedAt {
        key: DateTime
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserConnectionUsername {
        key: String
        connection: UsersPermissionsUserConnection
    }

    type UsersPermissionsUserGroupBy {
        id: [UsersPermissionsUserConnectionId]
        _id: [UsersPermissionsUserConnection_id]
        createdAt: [UsersPermissionsUserConnectionCreatedAt]
        updatedAt: [UsersPermissionsUserConnectionUpdatedAt]
        username: [UsersPermissionsUserConnectionUsername]
        email: [UsersPermissionsUserConnectionEmail]
        provider: [UsersPermissionsUserConnectionProvider]
        confirmed: [UsersPermissionsUserConnectionConfirmed]
        blocked: [UsersPermissionsUserConnectionBlocked]
        role: [UsersPermissionsUserConnectionRole]
        photo: [UsersPermissionsUserConnectionPhoto]
        name: [UsersPermissionsUserConnectionName]
        lastName: [UsersPermissionsUserConnectionLastName]
        celular: [UsersPermissionsUserConnectionCelular]
        corporate: [UsersPermissionsUserConnectionCorporate]
    }

    type Vehiculo {
        id: ID!
        _id: ID!
        createdAt: DateTime!
        updatedAt: DateTime!
        placa: String!
        tipo: ENUM_VEHICULO_TIPO!
        ciudadMatricula: String!
        marca: String!
        referencia: String
        modelo: Int!
        color: String
        estado: ENUM_VEHICULO_ESTADO!
        vin: String
        servicio: ENUM_VEHICULO_SERVICIO
        combustible: ENUM_VEHICULO_COMBUSTIBLE
        fotos(sort: String, limit: Int, start: Int, where: JSON): [UploadFile]
        comentarios(sort: String, limit: Int, start: Int, where: JSON): [Comentario]
    }

    type VehiculoAggregator {
        count: Int
        totalCount: Int
        sum: VehiculoAggregatorSum
        avg: VehiculoAggregatorAvg
        min: VehiculoAggregatorMin
        max: VehiculoAggregatorMax
    }

    type VehiculoAggregatorAvg {
        modelo: Float
    }

    type VehiculoAggregatorMax {
        modelo: Float
    }

    type VehiculoAggregatorMin {
        modelo: Float
    }

    type VehiculoAggregatorSum {
        modelo: Float
    }

    type VehiculoConnection {
        values: [Vehiculo]
        groupBy: VehiculoGroupBy
        aggregate: VehiculoAggregator
    }

    type VehiculoConnection_id {
        key: ID
        connection: VehiculoConnection
    }

    type VehiculoConnectionCiudadMatricula {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionColor {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionCombustible {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionCreatedAt {
        key: DateTime
        connection: VehiculoConnection
    }

    type VehiculoConnectionEstado {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionId {
        key: ID
        connection: VehiculoConnection
    }

    type VehiculoConnectionMarca {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionModelo {
        key: Int
        connection: VehiculoConnection
    }

    type VehiculoConnectionPlaca {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionReferencia {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionServicio {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionTipo {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoConnectionUpdatedAt {
        key: DateTime
        connection: VehiculoConnection
    }

    type VehiculoConnectionVin {
        key: String
        connection: VehiculoConnection
    }

    type VehiculoGroupBy {
        id: [VehiculoConnectionId]
        _id: [VehiculoConnection_id]
        createdAt: [VehiculoConnectionCreatedAt]
        updatedAt: [VehiculoConnectionUpdatedAt]
        placa: [VehiculoConnectionPlaca]
        tipo: [VehiculoConnectionTipo]
        ciudadMatricula: [VehiculoConnectionCiudadMatricula]
        marca: [VehiculoConnectionMarca]
        referencia: [VehiculoConnectionReferencia]
        modelo: [VehiculoConnectionModelo]
        color: [VehiculoConnectionColor]
        estado: [VehiculoConnectionEstado]
        vin: [VehiculoConnectionVin]
        servicio: [VehiculoConnectionServicio]
        combustible: [VehiculoConnectionCombustible]
    }

    input VehiculoInput {
        placa: String!
        tipo: ENUM_VEHICULO_TIPO!
        ciudadMatricula: String!
        fotos: [ID]
        marca: String!
        referencia: String
        modelo: Int!
        color: String
        comentarios: [ID]
        estado: ENUM_VEHICULO_ESTADO!
        vin: String
        servicio: ENUM_VEHICULO_SERVICIO
        combustible: ENUM_VEHICULO_COMBUSTIBLE
    }



`
