import {gql} from "apollo-boost";


// language=GraphQL
export const CREATE_VEHICLE_MUTATION = gql`
    mutation CreateVehicle ($plate: String!, $vehicleType: ENUM_VEHICULO_TIPO!, $vehicleStatus: ENUM_VEHICULO_ESTADO!, $vehicleModel:Int!) {
        createVehiculo(input: {data:
        {   placa:$plate,
            tipo: $vehicleType,
            estado: $vehicleStatus,
            ciudadMatricula: "Click para definir",
            modelo: $vehicleModel,
            color: "Click para definir",
            marca: "Click para definir",
            referencia: "Click para definir"
        }
        }){
            vehiculo{
                id
                placa
                tipo
                estado
                referencia
                marca
                modelo
            }

        }
    }
`;
