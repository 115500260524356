import {gql} from "apollo-boost";

export const FIND_USER_BY_ID_QUERY = gql`
    query FindUser($id: ID!) {
        user(id: $id){
            id
            username
            name
            lastName
            celular
            corporate
            photo{
                url
            }
        }
    }
`;
