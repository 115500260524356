import React, {Component} from "react";
import {Query} from "react-apollo";
import {FIND_BY_PLATE_VEHICLES_QUERY} from "../graph/query/VehicleQuery";
import {getGenericVehicle, Globals, isLoggedIn} from "../utils/Constants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Features from "../vehicle/Features";
import Chat from "../vehicle/Chat";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AcceptRegisterVehicle from "../vehicle/AcceptRegisterVehicle";
import NotFoundVehicle from "../vehicle/NotFoundVehicle";


export default class Vehicle extends Component {

  constructor(props) {
    super(props);
    let userId = localStorage.getItem(Globals.userIdKey)

    if (!props.location.state) {
      this.state = {
        vehicle: {},
        userId: userId,
        plate: sessionStorage.getItem(Globals.plateKey)
      };
      if (!this.state.plate) {
        this.props.history.push({
          pathname: '/home',
          state: {}
        })
      }
    } else {

      if (props.location.state.reload) {
        window.location.reload(false);
      }

      this.state = {
        vehicle: {},
        userId: userId,
        plate: props.location.state.plate
      };
      sessionStorage.setItem(Globals.plateKey, props.location.state.plate)
    }
  }

  reloadPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  }


  render() {
    return (
      <Query query={FIND_BY_PLATE_VEHICLES_QUERY} variables={{plate: this.state.plate}}>
        {({loading, error, data}) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;
          let vehicle = getGenericVehicle(this.state.plate);
          let foundVehicles = [];
          if (data.byPlate.length > 0) {
            vehicle = data.byPlate[0];
            foundVehicles = data.byPlate;
          } else if (data.byVin.length > 0) {
            vehicle = data.byVin[0];
            foundVehicles = data.byVin;
          }

          if (foundVehicles.length === 0 && !isLoggedIn()) {
            return (<NotFoundVehicle history={this.props.history}/>)
          } else if (foundVehicles.length === 0 && isLoggedIn()) {
            return (<AcceptRegisterVehicle plate={this.state.plate} history={this.props.history} onCompleteFunction={this.reloadPage}/>)
          } else {
            return (
              <Row className="vehicle">
                <Col xs={12} className="mobile-only">

                  <Tabs defaultActiveKey="chat" id="uncontrolled-tab-example">
                    <Tab eventKey="chat" title="Chat">
                      <Chat vehicle={vehicle} userId={this.state.userId} plate={this.state.plate}/>
                    </Tab>
                    <Tab eventKey="features" title="Ficha Técnica">
                      <Features history={this.props.history} vehicle={vehicle} plate={this.state.plate}/>
                    </Tab>
                  </Tabs>
                </Col>

                <Col lg={4} className="desktop-only">
                  <Features history={this.props.history} vehicle={vehicle} plate={this.state.plate}/>
                </Col>
                <Col lg={8} md={12} className="desktop-only">
                  <Chat vehicle={vehicle} userId={this.state.userId} plate={this.state.plate}/>
                </Col>
              </Row>
            );
          }
        }}
      </Query>
    );
  }
}
