import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import Logo from "./Logo";
import PubSub from 'pubsub-js'

import {createBrowserHistory} from 'history'
import {Events, Globals} from "./Constants";
import {Query} from "react-apollo";
import {FIND_USER_BY_ID_QUERY} from "../graph/query/UserQuery";
import Dropdown from "react-bootstrap/Dropdown";
import UserPhoto from "./UserPhoto";
import {FaBars} from "react-icons/all";

import {withSizes} from "react-sizes";

const history = createBrowserHistory()

class CustomNavbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: '',
      loggedIn: false,
      showLogo: window.location.href.indexOf("/home") < 0,
    };
  }

  componentDidMount() {
    history.listen((location, action) => {
      this.setState({
        showLogo: window.location.href.indexOf("/home") < 0
      });
    })

    PubSub.subscribe(Events.successLogin, (msg, data) => {
      this.setState({
        loggedIn: true,
        userId: localStorage.getItem(Globals.userIdKey)
      });
    });

    PubSub.subscribe(Events.cleanLogin, (msg, data) => {
      console.log("Cleaning Login");
      localStorage.removeItem(Globals.userIdKey)
      localStorage.removeItem(Globals.tokenKey)
      localStorage.removeItem(Globals.userKey)
      this.setState({
        loggedIn: false,
        userId: ''
      });
    });

    let userId = localStorage.getItem(Globals.userIdKey)
    let loggedIn = localStorage.getItem(Globals.tokenKey)!=null
    this.setState({
      loggedIn: loggedIn,
      userId: userId || ''
    });
  }

  logout = () => {
    localStorage.removeItem(Globals.userIdKey)
    localStorage.removeItem(Globals.tokenKey)
    localStorage.removeItem(Globals.userKey)

    this.setState({
      loggedIn: false,
      userId: ''
    });

    window.location.replace("/")

  };

  render() {
    return (
      <Navbar expand="lg" style={{height: "10%"}}>
        <Navbar.Brand href="#home">  {this.state.showLogo ?
          <Logo width={this.props.isMobile?"100px":"200px"} logoType="mainLogo"/> : ""}</Navbar.Brand>
        <Nav className="mr-auto"/>
        <Nav>

          {this.state.loggedIn ?
            <Query query={FIND_USER_BY_ID_QUERY} variables={{id: this.state.userId}}>
              {({loading, error, data}) => {
                if (loading) return 'Loading...';
                if (error) return `Error! ${error.message}`;
                if (data.user) {
                  localStorage.setItem(Globals.userKey, JSON.stringify(data.user));
                  return (<>
                    <Dropdown>
                      <Dropdown.Toggle variant="info" id="dropdown-basic">
                        <UserPhoto user={data.user} maxWidth={this.props.isMobile?"30px":"50px"}/> {data.user.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/my-profile">Mi Perfil</Dropdown.Item>
                        <Dropdown.Item className="mobile-only" href="/#/about-us">Nosotros</Dropdown.Item>
                        <Dropdown.Item className="mobile-only" href="/#/related-sites">Sitios Relacionados</Dropdown.Item>
                        <Dropdown.Item className="mobile-only" href="/#/contact-us">Contáctenos</Dropdown.Item>
                        <Dropdown.Item onClick={this.logout}>Salir</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>);
                } else {
                  return (
                    "SIVE"
                  );
                }
              }}
            </Query>
            :
            <>
              <Nav.Link className="desktop-only" href="/#/register">Registrate</Nav.Link>
              <Nav.Link className="desktop-only" href="/#/login">Ingreso</Nav.Link>
              <Dropdown className="mobile-only" drop="left">
                <Dropdown.Toggle variant="info" id="dropdown-basic">
                  <FaBars/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/#/register">Registrate</Dropdown.Item>
                  <Dropdown.Item href="/#/login">Ingreso</Dropdown.Item>
                  <Dropdown.Item href="/#/about-us">Nosotros</Dropdown.Item>
                  <Dropdown.Item href="/#/related-sites">Sitios Relacionados</Dropdown.Item>
                  <Dropdown.Item href="/#/contact-us">Contáctenos</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>

          }
        </Nav>
      </Navbar>
    )
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1400
})

export default withSizes(mapSizesToProps)(CustomNavbar)




