import React, {Component} from "react";
import {CREATE_COMMENT_MUTATION} from "../graph/mutation/CreateCommentMutation";
import Alert from "react-bootstrap/Alert";
import {getCurrentUser, Globals} from "../utils/Constants";
import OldMessages from "./OldMessages";
import Message from "./Message";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import {FaPaperPlane} from "react-icons/all";
import {Mutation} from "react-apollo";
import moment from "moment";
import socketIOClient from "socket.io-client";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
const currentDate = new Date();

const socket = socketIOClient(process.env.REACT_APP_SOCKET_SERVER);

export default class Chat extends Component {

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();

    this.state = {
      plate: this.props.plate,
      vehicle: this.props.vehicle,
      userId: this.props.userId,
      messages: [],
      message: "",
    };
  }

  componentDidMount() {
    let user = localStorage.getItem(Globals.userKey)
    socket.emit('join room', this.state.plate);

    if (this.state.userId) {
      socket.emit('chat connect', {
        senderName: `${user.name} ${user.lastName}`,
        nickname: user.username,
        userId: user.id,
        photo: user.photo ? user.photo.url : null
      });
    } else {
      socket.emit('chat connect', {
        senderName: "Anonimo",
        nickname: "Anonimo",
        userId: "anonimo",
        photo: null
      });
    }

    socket.on('chat message', msg => {
      this.setState(prevState => ({
        messages: [...prevState.messages, msg]
      }), () => {
        this.scrollDown();
      });
    });
  }

  scrollDown = () =>{
    this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight - this.scrollRef.current.clientHeight;
  }
  scrollUp = () =>{
    this.scrollRef.current.scrollTop = 0;
  }


  sendMessage = (event, saveMessage) => {
    let user = getCurrentUser();

    socket.emit('chat message', {
      roomId: this.state.plate,
      createdAt: moment(new Date()).format("DD-MMM-YYYY, hh:mm A"),
      text: this.state.message,
      vehiculo: {
        id: this.state.vehicle.id
      },
      "tipoComentario": "NORMAL",
      "user": {
        "id": user.id,
        "corporate": user.corporate,
        "name": user.name,
        "lastName": user.lastName,
        "photo": {
          "url": user.photo ? user.photo.url : null
        }
      }
    });
    saveMessage({
      variables: {
        text: this.state.message,
        date: new Date(),
        commentType: "NORMAL",
        user: this.state.userId,
        vehicle: this.state.vehicle.id
      }
    }).then(data => {
      console.log("messageSaved")
    });

    this.setState({
      message: "",
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  render() {
    return (
      <Mutation mutation={CREATE_COMMENT_MUTATION}>
        {saveMessage => (
          <div className="chat-box" style={{color: "black", height: "100%", overflowY: "hidden"}}>
            <Alert variant="info" className="mobile-only" style={{fontSize:"12px"}}>
              {Globals.siveWarning}
            </Alert>
            <Alert variant="info" className="desktop-only">
              {Globals.siveWarning}
            </Alert>
            <div style={{overflowY: "hidden"}} className="responsiveChatBox">
              <div ref={this.scrollRef} style={{height: "auto", overflowY: "scroll"}} className="responsiveChat">
                <OldMessages vehicle={this.state.vehicle.id} date={currentDate} userId={this.state.userId} scrollDown={this.scrollDown} scrollUp={this.scrollUp}/>
                {
                  this.state.messages.map((msg, idx) => {
                    return (
                      <Message vehicle={this.state.vehicle.id} message={msg} key={idx} userId={this.state.userId}/>
                    )
                  })
                }
              </div>
              <InputGroup className="col-md-12 chatTextArea" >
                <FormControl
                  as="textarea"
                  value={this.state.message}
                  name="message"
                  className="rounded-left"
                  placeholder="Escriba su mensaje..."
                  aria-label="Escriba su mensaje..."
                  aria-describedby="basic-addon2"
                  onKeyPress={event => {
                    let t = event.keyCode || event.which;
                    if (t !== 13 && t !== 1) {
                      return;
                    }
                    if (this.state.message.trim() === "") {
                      return;
                    }
                    this.sendMessage(event, saveMessage);
                  }}
                  onChange={e => this.handleChange(e)}
                />
                <InputGroup.Append>
                  <OverlayTrigger className="float-right" overlay={<Tooltip id="tooltip-disabled">Interactua con todas las personas que conocen este vehículo!</Tooltip>}>
                  <Button
                    variant="primary"
                    className="rounded-right"
                    onClick={event => {
                      this.sendMessage(event, saveMessage)
                    }}>
                    Enviar <FaPaperPlane/>
                  </Button>
                  </OverlayTrigger>
                </InputGroup.Append>
              </InputGroup>
            </div>

          </div>

        )}
      </Mutation>
    );
  }
}
