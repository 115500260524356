import React from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


class CustomFooter extends React.Component {

  render() {
    return (
      <Row className="footer desktop-only" >
        <Col className="col-sm-12 my-auto d-flex justify-content-center">
          <Nav>
            <Nav.Item>
              <Nav.Link href="/#/about-us">Nosotros</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/#/related-sites">Sitios Relacionados</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/#/contact-us">Contáctenos</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>)
  }
}

export default CustomFooter;


