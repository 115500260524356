import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {CustomValidations, Globals, YupValidations} from "../utils/Constants";
import * as yup from 'yup';
import {setLocale} from 'yup';
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import {Mutation} from "react-apollo";
import {FORGOT_PASSWORD} from "../graph/mutation/ForgotPasswordMutation";
import {Formik} from "formik";
import BrandLogo from "../utils/BrandLogo";

setLocale(YupValidations);

const schema = yup.object().shape({
  email: yup.string().email().required()
});


export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      email: ''
    };
  }

  render() {
    return (
      <Row>
        <Col lg={4} xs={1}/>
        <Col lg={4} xs={10} className="user_card" style={{color: "black"}}>
          <BrandLogo />
          <Mutation mutation={FORGOT_PASSWORD}
            variables={{
              email: this.state.email
            }}
            onError={(error) => {
              this.setState({
                error: true
              })
            }}
          >
            {(forgotForm, result) => {
              const {data, loading} = result;

              if (loading) {
                return (
                  <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
              } else {
                return (
                  <Formik
                    validationSchema={schema}
                    initialValues={{
                      email: '',
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,

                    }) => (
                      <Form className="form_container" noValidate onSubmit={event => {
                        handleSubmit();
                        if (!isValid) {
                          event.preventDefault();
                          event.stopPropagation();
                          return;
                        }
                        this.setState({
                          email: values.email,
                        }, () => {
                          return forgotForm();
                        })

                      }}>
                        {data && data.forgotPassword.ok ?
                          <>
                            <Alert variant="success">
                              {Globals.forgotPasswordSuccess}
                            </Alert>
                          </> : this.state.error ? <Alert variant="danger">
                            {Globals.forgotPasswordError}
                          </Alert> : ""
                        }

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Correo Electrónico</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="tucorreo@sive.com"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Button variant="primary" block type={"submit"}
                          disabled={!isValid || Object.values(touched).length === 0}>
                          Enviar código de recuperación
                        </Button>
                      </Form>
                    )}
                  </Formik>

                )
              }
            }}
          </Mutation>
        </Col>
        <Col lg={4} xs={1}/>
      </Row>
    )
  }
}
