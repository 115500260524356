import {gql} from "apollo-boost";

export const FIND_BY_PLATE_VEHICLES_QUERY = gql`
    query FindVehicle($plate: String!) {
        byPlate:vehiculos (where: { placa: $plate }){
            id
            placa
            tipo
            ciudadMatricula
            marca
            referencia
            modelo
            estado
            servicio
            combustible
            vin
            fotos {
                url
            }
        }
        byVin:vehiculos (where: { vin: $plate }){
            id
            placa
            tipo
            ciudadMatricula
            marca
            referencia
            modelo
            estado
            servicio
            combustible
            vin
            fotos {
                url
            }
        }
    }
`;

