import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import {Globals} from "../utils/Constants";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";

export default class ModalAuthorization extends Component {


  constructor(props) {
    super(props);
    this.state = {
      error: '',
      success: '',
      loading: false
    }
  }

  getUploadParams = ({meta}) => {
    return {url: 'https://httpbin.org/post'}
  }

  handleChangeStatus = ({meta, file}, status) => {
    console.log(status, meta, file)
  }

   uploadDocument = async (files) => {
    const data = new FormData();
    files.forEach(function (file) {
      data.append('files', file.file);
    });
    let token = localStorage.getItem(Globals.tokenKey);
    return await axios.post(process.env.REACT_APP_BACKEND_SERVER + "/upload", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

  }

  askManualVerification = () => {
    this.setState({
      error: '',
      success: '',
      loading: true
    });
    let token = localStorage.getItem(Globals.tokenKey);
    this.setState({
      loading: true,
    });
    axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/confirmation/manualValidation`, {
      "vehicleId": this.props.vehicle.id,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(_ => {
      this.setState({
        loading: false,
        success: Globals.manualVerificationAsked
      }, () => {
        setTimeout(() => {
          window.location.reload(false);
        }, 1000)
      });
    })
  }

  handleSubmit = async (files) => {
    this.setState({
      error: '',
      success: '',
      loading: true
    });
    let result = await this.uploadDocument(files);
    let token = localStorage.getItem(Globals.tokenKey);

    axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/confirmation/authorizeEdition`, {
      "vehicleId": this.props.vehicle.id,
      "url": result.data[0].url,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(_ => {

      setTimeout(() => {
        this.props.validator().then(_ => {
          this.setState({
            loading: false,
            success: Globals.identityVerified
          }, () => {
            setTimeout(() => {
              window.location.reload(false);
            }, 1000)
          })
        }).catch(_ => {
          this.setState({
            loading: false,
            error: Globals.identityVerificationError
          })
        })
      }, 1000)

    }).catch(_ => {
      this.setState({
        loading: false,
        error: Globals.identityVerificationError
      })
    });

  }


  render() {
    return (<Modal show={this.props.show} style={{color: "black"}}
      onHide={this.props.close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Validación de seguridad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>{Globals.identityVerificationInfo}</h6>
        {this.state.error ? <Alert variant="danger">{this.state.error} <Button variant="primary" onClick={this.askManualVerification}>Solicitar Revisión Manual</Button></Alert> : ""}
        {this.state.success ? <Alert variant="success">{this.state.success}
          <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>
        </Alert> : ""}
        {this.state.loading?
          <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>:
          <Card >
            <ListGroup variant="flush">
              <ListGroup.Item>1- Toma una fotografia del lado posterior de tu tarjeta con la mejor resolución posible, y con una buena fuente de luz. Dónde se pueda apreciar muy bien el código de barras
                <br />
                Ejemplo:
                <Image src="https://sive-files.s3.amazonaws.com/ejemplo_574f0a9325.png"
                  width="100px"
                />

              </ListGroup.Item>
              <ListGroup.Item>2-  Selecciona la fotografía haciendo clic en la siguiente caja o arrastra la foto al recuadro.
                <Dropzone
                  multiple={false}
                  maxFiles={1}
                  inputContent={"Click o arrastra para seleccionar la tarjeta de propiedad del vehículo"}
                  inputWithFilesContent={"Agregar fotos"}
                  submitButtonContent={"Validar mi identidad"}
                  getUploadParams={this.getUploadParams}
                  onChangeStatus={this.handleChangeStatus}
                  onSubmit={this.handleSubmit}
                  accept="image/*"
                />
              </ListGroup.Item>
              <ListGroup.Item>3- Has clic en el botón "Validar mi identidad"</ListGroup.Item>
              {this.state.error?<ListGroup.Item>4- En caso de que no podamos validar tu identidad, puedes hacer click en el botón "Solicitar Revisión Manual" y nos pondremos en contacto contigo
                <br />
                <Button variant="primary" onClick={this.askManualVerification}>Solicitar Revisión Manual</Button>
              </ListGroup.Item>:""}
            </ListGroup>
          </Card>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.close}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>);
  }
}
