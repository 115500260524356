import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Mutation} from "react-apollo";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import {CustomValidations, Globals, YupValidations} from "../utils/Constants";
import {Formik} from "formik";
import * as yup from 'yup';
import {setLocale} from 'yup';
import BrandLogo from "../utils/BrandLogo";
import {CREATE_CONTACT_MESSAGE_MUTATION} from "../graph/mutation/CreateContactMessage";
import Back from "../utils/Back";
import Card from "react-bootstrap/Card";

setLocale(YupValidations);

const schema = yup.object().shape({
  name: yup.string().required().max(20).min(5),
  text: yup.string().required().max(500).min(50),
  email: yup.string().email().required()
});

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      name: '',
      email: '',
      text: ''
    };
  }

  render() {

    return (
      <Row>
        <Col lg={4} xs={1}/>
        <Col lg={4} xs={10} className="user_card"
          style={{color: "black"}}>
          <BrandLogo/>
          <Card.Header style={{backgroundColor:"transparent", border: "none"}}><Back history={this.props.history} /></Card.Header>
          <Mutation mutation={CREATE_CONTACT_MESSAGE_MUTATION}
            variables={{
              name: this.state.name,
              email: this.state.email,
              text: this.state.text
            }}
            onError={(error) => {
              this.setState({
                error: true
              })
            }}
          >
            {(sendForm, result) => {
              const {data, loading} = result;

              if (loading) {
                return (
                  <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
              } else {
                return (
                  <Formik
                    validationSchema={schema}
                    initialValues={{
                      name: '',
                      email: '',
                      text: '',
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,

                    }) => (
                      <Form className="form_container" noValidate onSubmit={event => {
                        handleSubmit();

                        if (!isValid) {
                          event.preventDefault();
                          event.stopPropagation();
                          return;
                        }
                        this.setState({
                          name: values.name,
                          email: values.email,
                          text: values.text
                        }, () => {
                          return sendForm();
                        })
                      }}>
                        <h6>Hola, gracias por visitarnos, puedes escribirnos un mensaje en el siguiente formulario y pronto nos pondremos en contacto</h6>
                        <hr />
                        {data && data.createMensajeContacto.mensajeContacto.id ?
                          <Alert variant="success">
                            {Globals.contactUsSuccess}
                          </Alert> : this.state.error ? <Alert variant="danger">
                            {Globals.contactUsError}
                          </Alert> : ""
                        }

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Nombres</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Tu Nombre"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.name && !errors.name}
                              isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Correo Electrónico</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="tucorreo@sive.com"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control as="textarea"
                              type="text"
                              placeholder="Escribenos un mensaje"
                              name="text"
                              value={values.text}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.text && !errors.text}
                              isInvalid={!!errors.text}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.text}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Button variant="primary" type={"submit"}
                          disabled={!isValid || Object.values(touched).length === 0}>
                          Enviar
                        </Button>

                        <div className="mt-4">
                          <div className="d-flex justify-content-center links">
                            ¿Qué tal si ahora te unes a nuestra comunidad? <a href="/#/register" className="ml-2">Registrate</a>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>

                )
              }
            }}
          </Mutation>

        </Col>
        <Col lg={4} xs={1}/>
      </Row>
    )
  }
}
