import React, {Component} from "react";
import {getFormatDate} from "../utils/Constants";
import Logo from "../utils/Logo";
import Corporate from "../utils/Corporate";
import UserPhoto from "../utils/UserPhoto";

export default class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: this.props.vehicle,
      userId: this.props.userId,
      message: this.props.message || {}
    }
  }

  getSenderNameFromMessage = () => {
      return this.state.message.user ? this.state.message.user.name + " " + this.state.message.user.lastName : "Anonimo";
  }

  render() {
    return (<>
      {this.state.message.tipoComentario==="SISTEMA"?
        <div className="incoming_msg">
          <div className="incoming_msg_img">
            <Logo width="50px" logoType="justLogo" className="chat_logo"/>
          </div>
          <div className="received_msg">
            <div className="received_withd_msg">
              <span className="sender-name"> Mensaje del Sistema: </span>
              <p style={{fontSize:"12px", backgroundColor:"#fff3cd", color: "#856404"}}>{""+this.state.message.text}</p>
              <small className="time_date">{getFormatDate(this.state.message.createdAt)}</small>
            </div>
          </div>
        </div>:
        this.state.message.user && this.state.userId === this.state.message.user.id ?
          <div className="outgoing_msg">
            <div className="sent_msg">
                  <span
                    className="sender-name">{this.getSenderNameFromMessage()} dice: <Corporate user={this.state.message.user}/></span>
              <p>{this.state.message.text}</p>
              <small className="time_date">{getFormatDate(this.state.message.createdAt)}</small>
            </div>
          </div> :
          <div className="incoming_msg">
            <div className="incoming_msg_img">
              <UserPhoto maxWidth={this.props.isMobile?"40px":"60px"} user={this.state.message.user} />
            </div>
            <div className="received_msg">
              <div className="received_withd_msg">
                <span className="sender-name">{this.getSenderNameFromMessage()} dice: <Corporate user={this.state.message.user}/></span>
                <p>{this.state.message.text}</p>
                <small className="time_date">{getFormatDate(this.state.message.createdAt)}</small>
              </div>
            </div>
          </div>

      }
    </>);
  }
}
