import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {CustomValidations, Events, Globals, YupValidations} from "../utils/Constants";
import * as yup from 'yup';
import {setLocale} from 'yup';
import Spinner from "react-bootstrap/Spinner";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import {Mutation} from "react-apollo";
import CantProcess from "../utils/CantProcess";
import PubSub from "pubsub-js";
import queryString from "query-string";
import {RESET_PASSWORD} from "../graph/mutation/ForgotPasswordMutation";

setLocale(YupValidations);

const schema = yup.object().shape({
  password: yup.string().required().matches(CustomValidations.passwordRegex, CustomValidations.passwordRegexError),
  passwordConfirmation: yup.string().required()
    .oneOf([yup.ref('password'), null], 'El password y su confirmación no coinciden'),
});


export default class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: ''
    };
  }

  render() {
    let params = queryString.parse(this.props.location.search)

    if (params.code) {
      return (
        <Row>
          <Col lg={2}/>
          <Col lg={8} className="user_card" style={{color: "black"}}>
            <Mutation mutation={RESET_PASSWORD}
              variables={{
                code: params.code,
                password: this.state.password,
                passwordConfirmation: this.state.passwordConfirmation,
              }}
              onCompleted={(data) => {
                setTimeout(() => {
                  localStorage.setItem(Globals.tokenKey, data.resetPassword.jwt);
                  localStorage.setItem(Globals.userIdKey, data.resetPassword.user.id);
                  PubSub.publish(Events.successLogin, data);
                  this.props.history.push({
                    pathname: '/home',
                  })
                }, 2000);
              }}
              onError={(error) => {
                this.setState({
                  error: true
                })
              }}
            >
              {(resetForm, result) => {
                const {data, loading} = result;

                if (loading) {
                  return (
                    <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>)
                } else {
                  return (
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        password: '',
                        passwordConfirmation: '',
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,

                      }) => (
                        <Form noValidate onSubmit={event => {
                          handleSubmit();

                          if (!isValid) {
                            event.preventDefault();
                            event.stopPropagation();
                            return;
                          }
                          this.setState({
                            password: values.password,
                            passwordConfirmation: values.passwordConfirmation,
                          }, () => {
                            return resetForm();
                          })
                        }}>
                          {data && data.resetPassword.user.id ?
                            <Alert variant="success">
                              {Globals.resetPasswordSuccess} <Spinner animation="border" role="status"><span
                              className="sr-only">Cargando...</span></Spinner>
                            </Alert> : this.state.error ? <Alert variant="danger">
                              {Globals.resetPasswordError}
                            </Alert> : ""
                          }
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Label>Tu nueva contraseña</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Tu Contraseña"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                                isInvalid={!!errors.password}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                              <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Label>Confirma tu nueva contraseña</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Confirma tu Contraseña"
                                name="passwordConfirmation"
                                value={values.passwordConfirmation}
                                onChange={e=> {
                                  handleBlur(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                                isInvalid={!!errors.passwordConfirmation}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.passwordConfirmation}
                              </Form.Control.Feedback>
                              <Form.Control.Feedback>{CustomValidations.lookGood}</Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>

                          <Button variant="primary" type={"submit"}
                            disabled={!isValid || Object.values(touched).length === 0}>
                            Confirma tu nuevo password
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  )
                }
              }}
            </Mutation>

          </Col>
          <Col lg={2}/>
        </Row>
      )
    } else {
      return <CantProcess/>
    }
  }
}
