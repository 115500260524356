import {gql} from "apollo-boost";

// language=GraphQL
export const CREATE_CONTACT_MESSAGE_MUTATION = gql`
    mutation CreateUser ($email: String!, $name: String!, $text: String!) {
        createMensajeContacto(input:{ data:{nombre:$name, mensaje:$text, correo:$email}}){
            mensajeContacto{
                id
                nombre
                mensaje
                correo
            }
        }
    }
`;
