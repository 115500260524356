import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/custom-boostrap.scss';
import './App.css';

import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import {HashRouter as Router, Redirect, Route} from "react-router-dom";
import Alliances from "./components/pages/Alliances";
import CustomNavbar from "./components/utils/CustomNavbar";
import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from "apollo-boost";
import {ApolloProvider} from "react-apollo";
import CustomFooter from "./components/utils/CustomFooter";
import Register from "./components/pages/Register";
import Login from "./components/pages/Login";
import Vehicle from "./components/pages/Vehicle";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ForgotPassword from "./components/pages/ForgotPassword";
import {Globals} from "./components/utils/Constants";
import MyProfile from "./components/pages/MyProfile";
import {SIVE_SCHEMA} from "./components/graph/schema";
import ConfirmEmail from "./components/pages/ConfirmEmail";
import ResetPassword from "./components/pages/ResetPassword";
import RelatedSites from "./components/pages/RelatedSites";
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { from } from '@apollo/client';

let httpLink = new HttpLink({uri: `${process.env.REACT_APP_BACKEND_SERVER}/graphql`});
const removeTypenameLink = removeTypenameFromVariables();

const authLink = new ApolloLink((operation, forward) => {
  let token = localStorage.getItem(Globals.tokenKey);
  if (token) {
    operation.setContext({
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  } else {
    operation.setContext({
      headers: {}
    });
  }
  return forward(operation);
});

const client = new ApolloClient({
  link: from([removeTypenameLink, authLink.concat(httpLink)]), // Chain it with the HttpLink
  cache: new InMemoryCache(),
  typeDefs: SIVE_SCHEMA
});

function App() {
  return (
    <ApolloProvider client={client}>
      <CustomNavbar/>
      <Row style={{height: "80%" , paddingLeft: "2%"}}>
        <Col className="col-sm-12 my-auto">
          <Router>
            <Route exact path="/">
              <Redirect to="/home"/>
            </Route>
            <Route path="/home" exact component={Home}/>
            <Route path="/vehicle" exact component={Vehicle}/>
            <Route path="/about-us" exact component={AboutUs}/>
            <Route path="/alliances" exact component={Alliances}/>
            <Route path="/contact-us" exact component={ContactUs}/>
            <Route path="/register" exact component={Register}/>
            <Route path="/confirm-email" exact component={ConfirmEmail}/>
            <Route path="/login" exact component={Login}/>
            <Route path="/forgot-password" exact component={ForgotPassword}/>
            <Route path="/reset-password" exact component={ResetPassword}/>
            <Route path="/my-profile" exact component={MyProfile}/>
            <Route path="/related-sites" exact component={RelatedSites}/>
          </Router>
        </Col>
      </Row>
      <CustomFooter/>
    </ApolloProvider>
  );
}

export default App;
