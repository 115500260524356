import {gql} from "apollo-boost";


// language=GraphQL
export const ROTATE_IMAGE_MUTATION = gql`
    mutation RotateImage($url:String!){
        rotateImage(url:$url)
    }
`;

