import {gql} from "apollo-boost";

// language=GraphQL
export const LOGOS_QUERY = gql`
    query Logos($logoType: String!) {
        logos (where: { logoType: $logoType }){
            logoType
            file {
                url
            }
        }
    }
`;

