import moment from "moment";
import React from "react";

export const Globals = {
  plateKey: "plate",
  userIdKey: "userId",
  userKey: "user",
  tokenKey: "token",
  defaultVehiclePhoto: "https://sive-files.s3.amazonaws.com/not_found_vehicle_a836f40cfc.png",
  siveWarning : "SIVE te proporciona este espacio, para encontrar información útil acerca de la situación de los vehículos registrados. No envies información sensible através del chat.",
  registerSuccess: "Tu registro ha sido existoso, hemos enviado un mensaje a tu correo para que confirmes tu usuario en SIVE",
  registerFailed: "Ocurrió un error al procesar tu registro, es posible que tu celular o correo ya están registrados en SIVE, si es éste el caso puedes reestablecer tu contraseña",
  contactUsSuccess: "Hemos recibido tu mensaje, pronto te daremos una respuesta",
  contactUsError: "No hemos podido recibir tu mensaje, por favor inténtalo más tarde",
  completeSuccess: "Hemos registrado tus datos con éxito, en segundos podrás hacer uso de SIVE",
  completeError: "No ha sido posible completar tu registro, por favor revisa tus datos e intenta de nuevo",
  updateSuccess: "Tus datos han sido actualizados con éxito",
  updateError: "No ha sido posible actualizar tus datos, por favor revísalos e intenta de nuevo",
  confirmSuccess: "Hemos confirmado tu correo exitosamente, por favor diligencia estos datos finales para completar tu registro",
  registerInstructions: "Hola de nuevo. Hemos enviado un mensaje a tu celular con un código de seguridad, por favor digita el código y has clic en el botón para continuar",
  confirmError: "No ha sido posible confirmar tu correo, por favor intenta de nuevo en unos minutos",
  requestProcessError: "No podemos procesar tu solicitud, has click para ir al inicio",
  forgotPasswordSuccess: "Te hemos enviado un correo electrónico con un link para que puedas reasignar tu contraseña",
  forgotPasswordError: "No hemos podido validar tu identidad, por favor verifica que tu correo sea corrrecto",
  resetPasswordSuccess: "Tu password ha sido reestablecido de manera exitosa, en un momento te redirigiremos a la página principal",
  updatePasswordSuccess: "Has actualizado tu password exitosamente",
  updatePasswordError: "No ha sido posible actualizar tu password, intentalo de nuevo más tarde",
  resetPasswordError: "No ha sido posible reestablecer tu password. Por favor revisa tus datos e intenta nuevamente",
  siveJoinTitle: "¡Únete a nuestra comunidad!",
  siveJoinUs: "SIVE es una iniciativa que te permitirá publicar el estado de tu vehículo, y consultar el estado de otros vehículo conectando propietarios de todo el país, para contribuir a la transparencia en los negocios de vehículos, y combatir la delincuencia.",
  notFoundWaring: "El vehículo que estás buscando, aún no hace parte de nuestra base de datos, registrate en SIVE y publica la información del vehículo",
  createVehicleSuccess: "Hemos registrado el vehículo exitosamente en SIVE, en unos segundos te enviaremos a la página principal del vehículo, para que diligencies datos adicionales",
  createVehicleError: "No ha sido posible registrar el vehículo en SIVE, si requieres soporte puedes comunicarte con nosotros",
  createVehicleInfo: "El vehículo que buscas aún no se encuentra en nuestra base de datos, a continuación te pedimos algunos datos básicos para empezar con el registro",
  toolTipFindVehicle: "Digita la placa o el VIN de tu vehículo para realizar la búsqueda en SIVE. Con el registro de tu vehículo contribuyes a bajar los indices de estafa y delincuencia en el país",
  loginError: "No hemos podido validar tu información, revísala e intenta de nuevo. Si ya estás registrado es posible que no hayas confirmado tu correo",
  neverShareInfo: "Nunca compartiremos tu email con nadie más.",
  confirmPhone: "Ya casi terminamos, te pedimos que valides también tu número de celular. Confirmamos tu identidad para mantener nuestra plataforma segura",
  phoneErrorValidation: "No hemos podido enviar el mensaje de texto a tu celular, por favor verifica la información",
  verifiedUser: "Este usuario está verificado en nuestro sistema, y sus comentarios son verídicos",
  identityVerified: "Hemos validado tu identidad, en segundos podrás editar los datos de tu vehículo",
  manualVerificationAsked:  "Hemos recibido tu solicitud, pronto nos pondremos en contacto contigo para confirmar tus datos",
  identityVerificationError: "No hemos podido validar tu identidad, verifica que el documento coincida con el vehículo e intenta de nuevo",
  identityVerificationInfo: "Aún no hemos validado tu relación con éste vehículo, por seguridad requerimos hacerlo. Para ello requerimos validar tu tarjeta de propiedad, sigue los pasos para continuar"

};

export const CustomValidations =  {
  passwordRegex : "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  passwordRegexError: "Tu password debe contener al menos:  8 caracteres, una letra mayúscula, una letra minúscula, un caracter especial y un número",
  lookGood: "Es correcto",
  numberRegex: "^[0-9]*$" ,
  numberRegexError: "Este campo sólo admite números",
  passwordMatchError: "El password y su confirmación no coinciden"
}
/* eslint-disable */
export const YupValidations = {
  mixed : {
    default: "Este campo es invalido",
    required: "Este campo es obligatorio",
  },
  string : {
    length: "Este campo debe tener ${length} caracteres exactamente",
    min: 'Este campo debe tener almenos ${min} caracteres',
    max: 'Este campo puede tener máximo ${max} caracteres',
    matches: 'Este campo no cumple las validaciones requeridas',
    email: 'Este campo debe ser un email valido',
    url: 'Esca campo debe ser una URL válida',
    lowercase: 'Sólo se pueden usar letras minúsculas en este campo',
    uppercase: 'Sólo se pueden usar letras mayúsculas en este campo'
  },
   number : {

    min: 'El valor de este campo debe ser mayor o igual a ${min}',
    max: 'El valor de este campo debe ser máximo ${max}',
    lessThan: 'El valor del campo debe ser menos que ${less}',
    moreThan: 'El valor del campo debe ser mayor a ${more}',
    notEqual: 'El valor del campo no puede ser igual a ${notEqual}',
    positive: 'Debe digitar un número positivo'

  }
}




export const Events = {
  successLogin: "successLogin",
  cleanLogin: "cleanLogin"

}

export const statusTypeList = [
  {key: "NO_ESTA_EN_VENTA", text: "NO ESTÁ EN VENTA", color: "#d4edda"},
  {key: "PROCESO_DE_VENTA", text: "ESTÁ EN VENTA", color: "#cce5ff"},
  {key: "PATIOS_INMOVILIZADO", text: "EN PATIOS", color: "#fff3cd"},
  {key: "PERDIDA_TOTAL", text: "PÉRDIDA TOTAL", color: "#f8d7da"},
  {key: "PLEITO_LEGAL", text: "EN PROCESO LEGAL", color: "#fff3cd"},
  {key: "ROBADO",text: "ROBADO", color: "#f8d7da"}
];



export const vehicleTypeList = [
  {key: "AUTOMOVIL", text : "AUTOMÓVIL"},
  {key: "BUS", text : "BUS"},
  {key: "BUSETA", text :"BUSETA" },
  {key: "CAMION", text : "CAMIÓN"},
  {key: "CAMIONETA", text : "CAMIONETA"},
  {key: "CAMPERO", text : "CAMPERO"},
  {key: "MICROBUS", text : "MICROBÚS"},
  {key: "TRACTOCAMION", text :"TRACTOCAMIÓN" },
  {key: "MOTOCICLETA", text : "MOTOCICLETA"},
  {key: "MOTOCARRO", text : "MOTOCARRO"},
  {key: "MOTOTRICICLO", text : "MOTOTRICICLO"},
  {key: "CUATRIMOTO", text : "CUATRIMOTO"},
  {key: "VOLQUETA", text :"VOLQUETA" }
];

export const fuelList= [
  {key: "GASOLINA", text: "GASOLINA"},
  {key: "GNV", text: "GAS NATURAL VEHICULAR (GNV)"},
  {key: "DIESEL", text: "DIESEL"},
  {key: "GASOLINA_GAS", text: "GASOLINA-GAS"},
  {key: "ELECTRICO", text: "ELÉCTRICO"},
  {key: "HIDROGENO", text: "HIDRÓGENO"},
  {key: "ETANOL", text: "ETANOL"}
]
export const serviceList = [
  {key: "PARTICULAR", text: "PARTICULAR"},
  {key: "PUBLICO", text: "PÚBLICO"},
  {key: "DIPLOMATICO", text: "DIPLOMÁTICO"},
  {key: "OFICIAL", text: "OFICIAL"}
]


export const humanVehicleTypes = function (word) {
  return vehicleTypeList.filter((item) => item.key === word).shift();
}

export const statusTypes = function (word) {
   return statusTypeList.filter((item) => item.key === word).shift();
}

export const getFormatDate = function(date){
  return moment(date).format("DD-MMM-YYYY, hh:mm A")
}

export const isLoggedIn = function(){
  return localStorage.getItem(Globals.userKey)!==null;
}

export const getCurrentUser = function(){
  let user = {
    id: null,
    name: "Usuario",
    lastName: "Anonimo",
    photo: null
  }
  if (localStorage.getItem(Globals.userKey)) {
    user = JSON.parse(localStorage.getItem(Globals.userKey));
  }
  return user;
}


export const getGenericVehicle = (plate) => {
  return {
    id: "",
    placa: plate,
    tipo: "OTRO",
    ciudadMatricula: "",
    marca: "",
    referencia: "",
    modelo: new Date().getFullYear(),
    estado: "NO_ESTA_EN_VENTA",
    fotos: []
  }
}


export const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1400,
})
