import React, {Component} from "react";
import Avatar from 'react-avatar';

export default class UserPhoto extends Component {

  constructor(props) {

    super(props);
    this.state = {
      user: this.props.user,
      maxWidth: this.props.maxWidth ? this.props.maxWidth : "50px"
    }
  }

  getCompleteName = () => {
    if (this.state.user) {
      return (this.state.user.name || "").trim() + " " + (this.state.user.lastName || "").trim()
    }
    return "Usuario Anonimo"
  }

  render() {
    return (<>
      {this.state.user && this.state.user.photo ? <Avatar src={this.state.user.photo.url} round={true} size={this.state.maxWidth}/> :
        <Avatar name={this.getCompleteName()} round={true} maxInitials={2} size={this.state.maxWidth}
          initials={(name) => {
            console.log(name);
            let initials = "UA";
            if (name) {
              let allNames = name.split(" ")
              if (allNames.length >= 4) {
                initials = allNames[0][0] + allNames[2][0]
              } else if (allNames.length >= 2) {
                initials = allNames[0][0] + allNames[1][0]
              } else if(allNames.length >= 1) {
                initials = allNames[0][0]
              }
            }
            return initials.toUpperCase();
          }}/>
      }
    </>);
  }
}
