import React, {Component} from "react";
import {Query} from "react-apollo";
import {LOGOS_QUERY} from "../graph/query/LogoQuery";
import Image from "react-bootstrap/Image";

export default class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoType: this.props.logoType || "mainLogo",
      maxWidth: this.props.maxWidth || "500px",
      width: this.props.width || "80%",
      className: this.props.className || ""
    };
  }

  render() {
    return (
      <Query query={LOGOS_QUERY} variables={{logoType: this.state.logoType}}>
        {({loading, error, data}) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;
          if (data.logos.length > 0) {
            return (<Image
              style={{maxWidth: this.state.maxWidth}}
              width={this.state.width}
              className={this.state.className}
              src={data.logos[0].file.url}/>);
          } else {
            return (
              "SIVE"
            );
          }
        }}
      </Query>
    );
  }
}
