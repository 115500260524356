import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import {Globals} from "../utils/Constants";
import Register from "../pages/Register";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";

export default class NotFoundVehicle extends Component {

  render() {
    return (<Row className="vehicle">
      <Col xs={12} className="mobile-only">
        <Alert variant="warning">{Globals.notFoundWaring}</Alert>
        <Register history={this.props.history}/>
      </Col>

      <Col lg={5} className="desktop-only" style={{color: "black"}}>
        <Jumbotron>
          <h2>{Globals.siveJoinTitle}</h2>
          <Alert variant="warning">{Globals.notFoundWaring}</Alert>
          <p>
            {Globals.siveJoinUs}
          </p>
          <p>
          </p>
        </Jumbotron>
      </Col>
      <Col lg={7} md={12} className="desktop-only">
        <Register intern={true} history={this.props.history}/>
      </Col>
    </Row>);
  }
}
