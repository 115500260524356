import {gql} from "apollo-boost";

// language=GraphQL
export const ABOUT_US_QUERY = gql`
    query {
        nosotros{
            titulo
            texto
            tipo
            enlace
            textoEnlace
            foto{
                url
            }
        }
    }
`;

