import React, {Component} from "react";
import InputGroup from "react-bootstrap/InputGroup";
import {FormControl} from "react-bootstrap";
import {FaCheck, FaEdit, FaTimes} from "react-icons/all";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export default class Editable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inEdition: false,
      blocked: this.props.blocked,
      loading: false,
      fromList: this.props.fromList || null,
      fieldName: this.props.fieldName,
      fieldValue: this.props.fieldValue || (this.props.fromList?this.props.fromList[0].key:""),
      fieldPlaceholder: this.props.fieldPlaceholder,
      saveFunction: this.props.saveFunction || this.dummySaveFunction
    };
  }

  dummySaveFunction = () => {
    console.log(`Editing ${this.state.fieldName} please implement real function`);
  }

  enableDisableEdition = () => {
    if(!this.props.blocked) {
      if (this.props.serverValidation) {
        this.props.serverValidation().then(data => {
          this.successValidation();
        }).catch(_ => {
          this.props.onServerErrorValidation();
        })
      } else {
        this.successValidation();
      }
    }
  }

  successValidation = ()=>{
    if (!this.state.inEdition && this.props.fieldValue === "Click para definir") {
      this.setState({
        fieldValue: ""
      })
    }
    this.setState({
      inEdition: !this.state.inEdition
    })
  }

  loading = () => {
    this.setState({
      loading: true
    })
  }

  noLoading = () => {
    this.setState({
      loading: false
    })
  }

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      fieldValue: e.target.value
    })
  };

  render() {
    return (<>
      {this.state.inEdition && !this.state.blocked?
        <InputGroup>
          <InputGroup.Prepend>
            {this.state.loading?
              <Spinner animation="border" size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>:
              <InputGroup.Text id={this.state.fieldName}>{this.state.fieldName}</InputGroup.Text>
            }

          </InputGroup.Prepend>
          {this.state.fromList?
            <FormControl as="select"
              aria-label={this.state.fieldName}
              aria-describedby={this.state.fieldName}
              value={this.state.fieldValue}
              name={this.state.fieldName}
              onChange={e => this.handleChange(e)}>
              {this.state.fromList.map((item, idx) => (
                <option key={idx} value={item.key}>{item.text}</option>
              ))}
            </FormControl>:
            <FormControl
              placeholder={this.state.fieldPlaceholder}
              aria-label={this.state.fieldName}
              aria-describedby={this.state.fieldName}
              value={this.state.fieldValue}
              name={this.state.fieldName}
              onChange={e => this.handleChange(e)}
            />
          }

          <InputGroup.Append>
            <Button  onClick={_=>{
              this.loading();
              this.enableDisableEdition();
              this.state.saveFunction(this.state.fieldValue).then(_=>{
                this.noLoading();
              });
            }} variant="primary"> <FaCheck/> </Button>
            <Button onClick={this.enableDisableEdition} variant="danger"> <FaTimes/> </Button>
          </InputGroup.Append>
        </InputGroup>:
        <Button onClick={this.enableDisableEdition} style={{backgroundColor: "transparent",borderColor: "transparent"}} variant={"light"}>{this.state.fieldName} : {" "}
          {this.state.fromList?this.state.fromList.filter((item) => item.key === this.state.fieldValue).shift().text:this.state.fieldValue} {" "}
          {this.state.loading?
            <Spinner animation="border" size="sm" role="status"><span className="sr-only">Loading...</span></Spinner>:
            !this.state.blocked?<FaEdit/>:""
          }
         </Button>
      }

    </>);
  }
}
